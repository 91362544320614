import { useState, useMemo, useEffect, useCallback, createElement } from 'react'
import { Trans } from '@lingui/macro'
import copyText from 'copy-text-to-clipboard'
import BigNumber from 'bignumber.js'
import { ID } from '@moverfinance/dex-sdk'
import { wallets, Wallet } from 'constants/wallet'
import { TYPE_APPROVE, TYPE_SWAP } from 'constants/tx'
import { NATIVE_CURRENCIES } from 'constants/currency'
import { useBalance } from 'hooks/useBalance'
import { useUserWeb3, useDisconnect } from 'hooks/useWeb3'
import { useRecentTxs } from 'hooks/useTx'
import { link, ExplorerDataType } from 'utils/explorer'
import { short } from 'utils/address'
import { ReactComponent as Done } from 'assets/icons/done.svg'
import * as Gradient from 'components/gradient'
import Button from 'components/button'
import Modal from 'components/modal'
import Avatar from './avatar'
import Approve from './approve'
import Swap from './swap'
import styles from './styles.module.scss'

let timer: NodeJS.Timer | null = null

const Account = () => {
  const [visible, setVisible] = useState(false)
  const [copied, setCopied] = useState(false)
  const { chainId, account, connector } = useUserWeb3()
  const currency = NATIVE_CURRENCIES[chainId as ID]
  const balance = useBalance(currency)
  const txs = useRecentTxs()
  const copy = useCallback(() => {
    if (account) {
      copyText(account)
      setCopied(true)
    }
  }, [account])
  const wallet = useMemo(
    () => wallets.find(wallet => wallet.connector === connector) as Wallet,
    [connector]
  )
  const disconnect = useDisconnect(() => {
    const sure = window.confirm(
      'Are you sure you want to disconnect your wallet?'
    )

    return Promise.resolve(sure)
  })

  useEffect(() => {
    if (timer) clearTimeout(timer)
    if (copied) timer = setTimeout(() => setCopied(false), 1000)
  }, [copied])

  return (
    <>
      <Button className={styles.toggle} onClick={() => setVisible(true)}>
        {balance && (
          <span className={styles.balance}>
            <Gradient.Text>
              {new BigNumber(balance).toFormat(4)} {currency.symbol}
            </Gradient.Text>
          </span>
        )}
        <span className={styles.address}>{short(account as string)}</span>
        <Avatar address={account as string} />
      </Button>
      <Modal
        title={<Trans>Account</Trans>}
        visible={visible}
        onClose={setVisible}
      >
        <div className={styles.account}>
          <div className={styles.logo}>
            {createElement(wallet.logo, { className: styles.icon })}
            <Done className={styles.done} />
          </div>
          <p className={styles.description}>
            <Trans>Connected with {wallet.name}</Trans>
          </p>
          <div className={styles.address}>
            <Avatar address={account as string} />
            <span className={styles.text}>{short(account as string)}</span>
            <hr />
            <button onClick={copy}>
              {copied ? (
                <Gradient.Text>
                  <Trans>Copied!</Trans>
                </Gradient.Text>
              ) : (
                <span>
                  <Trans>Copy Address</Trans>
                </span>
              )}
            </button>
          </div>
          <div className={styles.operations}>
            <div>
              <Button
                className={styles.button}
                onClick={() =>
                  window.open(
                    link(
                      chainId as ID,
                      account as string,
                      ExplorerDataType.ADDRESS
                    )
                  )
                }
              >
                <Trans>View on Explorer</Trans>
              </Button>
            </div>
            <div>
              <Button className={styles.button} onClick={disconnect}>
                <Trans>Disconnect</Trans>
              </Button>
            </div>
          </div>
          <hr className={styles.line} />
          {txs && txs.length ? (
            <div className={styles.tsx}>
              <h4 className={styles.title}>
                <Trans>Recent Transactions</Trans>
              </h4>
              <ul className={styles.list}>
                {txs.slice(0, 3).map(tx =>
                  tx.type === TYPE_APPROVE ? (
                    <li key={tx.txid}>
                      <Approve tx={tx} />
                    </li>
                  ) : tx.type === TYPE_SWAP ? (
                    <li key={tx.txid}>
                      <Swap tx={tx} />
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          ) : (
            <p className={styles.empty}>
              <Trans>Your transactions will appear here...</Trans>
            </p>
          )}
        </div>
      </Modal>
    </>
  )
}

export default Account
