import { HashRouter, Route, Switch } from 'react-router-dom'
import Layout from 'components/layout'
import Home from 'pages/home'
import Swap from 'pages/swap'
import Privacy from 'pages/privacy'
import Terms from 'pages/terms'
import NotFound from 'pages/not-found'

const Router = () => (
  <HashRouter>
    <Switch>
      <Route exact strict path="/" component={Home} />
      <Route
        render={() => (
          <Layout>
            <Switch>
              <Route exact strict path="/swap" component={Swap} />
              <Route exact strict path="/privacy" component={Privacy} />
              <Route exact strict path="/terms" component={Terms} />
              <Route component={NotFound} />
            </Switch>
          </Layout>
        )}
      />
    </Switch>
  </HashRouter>
)

export default Router
