import { useSelector as useReduxSelector } from 'react-redux'
import { RootStore } from 'store'
import reducer, { initialState } from './reducer'

export {
  updateSlippage,
  updateTxSpeed,
  updateExcludedExchanges
} from './actions'
export const KEY = 'setting'
export const useSelector = <T>(getter: (state: typeof initialState) => T) =>
  useReduxSelector((state: RootStore) => getter(state[KEY]))
export default reducer
