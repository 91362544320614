import { useCallback } from 'react'
import { Contract } from '@ethersproject/contracts'
import { Web3Provider, Provider } from '@ethersproject/providers'
import { Signer } from '@ethersproject/abstract-signer'
import { AddressZero } from '@ethersproject/constants'
import { isAddress } from '@ethersproject/address'
import { ID } from '@moverfinance/dex-sdk'
import {
  InvalidAddressError,
  LibraryNotExistError,
  WalletNotConnectedError
} from 'errors/web3'
import { useActiveWeb3 } from 'hooks/useWeb3'
import ERC20_ABI from 'abis/erc20.json'
import MULTICALL_ABI from 'abis/multicall.json'
import { Erc20, Multicall } from 'abis/types'
import { MULTICALL } from 'constants/addresses'

const getContract = (
  address: string,
  ABI: any,
  signerOrProvider: Provider | Signer
): Contract => {
  if (!isAddress(address) || address === AddressZero) {
    throw new InvalidAddressError(address)
  }

  return new Contract(address, ABI, signerOrProvider)
}

const useContract = <T extends Contract = Contract>(
  ABI: any,
  useSigner?: boolean
): ((address: string) => T) => {
  const { account, library } = useActiveWeb3()

  return useCallback(
    (address: string) => {
      if (!library) throw new LibraryNotExistError()
      if (!useSigner) return getContract(address, ABI, library)
      if (!account) throw new WalletNotConnectedError()
      const signer = (library as Web3Provider)
        .getSigner(account)
        .connectUnchecked()

      return getContract(address, ABI, signer) as any
    },
    [account, library, ABI, useSigner]
  )
}

export const useERC20Contract = (useSigner?: boolean) => {
  const contract = useContract<Erc20>(ERC20_ABI, useSigner)

  return useCallback((address: string) => contract(address), [contract])
}

export const useMulticallContract = () => {
  const contract = useContract<Multicall>(MULTICALL_ABI)

  return useCallback(
    (chainId: ID) => {
      const address = MULTICALL[chainId]

      return contract(address)
    },
    [contract]
  )
}
