/* eslint-disable */
import { useState, useCallback, useEffect } from 'react'
import axios, { CancelToken } from 'axios'
import BigNumber from 'bignumber.js'
import {
  ID,
  NATIVE_CURRENCY_ADDRESS,
  CurrencyAmount
} from '@moverfinance/dex-sdk'
import { COINGECKO_IDS, WRAPPED_ADDRESSED } from 'constants/coingecko'
import { UnsupportedChainIdError } from 'errors/web3'
import { ExternalServerError, InternalServerError } from 'errors/http'
interface FaitPrice {
  market_data: {
    current_price: {
      usd: number
    }
  }
}

export const useFaitPrice = (amount: CurrencyAmount | null) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [price, setPrice] = useState<string | null>(null)
  const getPrice = useCallback(
    async (amount: CurrencyAmount, cancelToken: CancelToken) => {
      const { currency } = amount
      const id = COINGECKO_IDS[currency.chainId as ID]

      setPrice(null)
      setError(null)
      if (!id) {
        setError(new UnsupportedChainIdError(currency.chainId))
        return
      }
      const address =
        currency.address === NATIVE_CURRENCY_ADDRESS
          ? WRAPPED_ADDRESSED[currency.chainId as ID]
          : currency.address
      const url = `https://api.coingecko.com/api/v3/coins/${id}/contract/${address?.toLocaleLowerCase()}`

      setLoading(true)
      try {
        const { data } = await axios.get<FaitPrice>(url, { cancelToken })

        try {
          setPrice(
            new BigNumber(data.market_data.current_price.usd)
              .multipliedBy(amount.toFixed())
              .toString()
          )
        } catch (err) {
          setError(new InternalServerError(url, err))
        }
      } catch (err) {
        setError(new ExternalServerError(url, err?.response?.data))
      }
      setLoading(false)
    },
    []
  )

  useEffect(() => {
    if (!amount) return
    const source = axios.CancelToken.source()
    const timer = setTimeout(() => getPrice(amount, source.token), 1000)

    return () => {
      if (timer) {
        clearTimeout(timer)
        source.cancel('Get fiat price operation canceled')
      }
    }
  }, [amount, getPrice])

  return { loading, error, price: !loading && !error ? price : undefined }
}

export default useFaitPrice
