import { createContext, useState, useCallback, FC } from 'react'
import { Currency } from '@moverfinance/dex-sdk'
import merge from 'lodash/merge'
import { useBalances } from 'hooks/useBalance'

export const Context = createContext<{
  balances: { [chainId: string]: { [address: string]: string } }
  add: (currency: Currency) => void
  remove: (currency: Currency) => void
}>({
  balances: {},
  /* eslint-disable @typescript-eslint/no-empty-function */
  add: () => {},
  remove: () => {}
  /* eslint-enable @typescript-eslint/no-empty-function */
})

const Balance: FC = ({ children }) => {
  const [addresses, setAddresses] = useState<{
    [chainId: string]: { [address: string]: number }
  }>({})
  const add = useCallback((currency: Currency) => {
    const { chainId, address } = currency

    setAddresses(addresses =>
      merge(addresses, {
        [chainId]: {
          [address]: (addresses?.[chainId]?.[address] ?? 0) + 1
        }
      })
    )
  }, [])
  const remove = useCallback((currency: Currency) => {
    const { chainId, address } = currency

    setAddresses(addresses =>
      merge(addresses, {
        [chainId]: {
          [address]: (addresses?.[chainId]?.[address] ?? 0) - 1
        }
      })
    )
  }, [])
  const filtered = Object.entries(addresses).reduce<{
    [chainId: string]: string[]
  }>((res, [chainId, items]) => {
    res[chainId] = Object.entries(items)
      .filter(([, count]) => count > 0)
      .map(([address]) => address)
    return res
  }, {})
  const { balances } = useBalances(filtered)

  return (
    <Context.Provider value={{ add, remove, balances }}>
      {children}
    </Context.Provider>
  )
}

export default Balance
