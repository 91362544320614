import { FC } from 'react'
import cls from 'classnames'
import styles from './styles.module.scss'

interface Props {
  className?: string
}

const Text: FC<Props> = ({ children, className }) => (
  <span className={cls(styles.text, className)}>{children}</span>
)

export default Text
