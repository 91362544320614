import { createElement, FC } from 'react'
import { Select, Trans } from '@lingui/macro'
import { Wallet } from 'constants/wallet'
import { ReactComponent as Security } from 'assets/icons/security.svg'
import styles from './styles.module.scss'

interface Props {
  wallet: Wallet
}

const Context: FC<Props> = ({ children, wallet }) => (
  <div className={styles.body}>
    <Security className={styles.logo} />
    <p className={styles.description}>
      <Trans>
        By connecting a wallet, you agree to Woven&apos;{' '}
        <a href="/terms" target="_blank">
          Terms of Service
        </a>{' '}
        &nbsp;and acknowledge that you have read and understand the{' '}
        <a href="/protocol" target="_blank">
          Woven protocol disclaimer
        </a>
        .
      </Trans>
    </p>
    {children}
    <footer className={styles.footer}>
      <div className={styles.context}>
        <h5 className={styles.title}>{wallet.name}</h5>
        <p className={styles.text}>
          <Select
            value={wallet.key}
            injected="Easy-to-use browser extension."
            coinbase="Easy-to-use browser extension."
            other="Wallet application"
          />
        </p>
      </div>
      {createElement(wallet.logo, { className: styles.icon })}
    </footer>
  </div>
)

export default Context
