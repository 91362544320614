import { useState, useEffect, useMemo, useCallback, FC } from 'react'
import { Trans } from '@lingui/macro'
import { TransactionResponse } from '@ethersproject/abstract-provider'
import BigNumber from 'bignumber.js'
import { useDispatch } from 'react-redux'
import { CurrencyAmount } from '@moverfinance/dex-sdk'
import { TYPE_SWAP } from 'constants/tx'
import { add } from 'store/tx'
import { Tx } from 'hooks/useTx'
import { Order } from 'hooks/useSwap'
import useFaitPrice from 'hooks/useFaitPrice'
import { UpdateProps as ModalUpdateProps } from 'components/modal'
import Button from 'components/button'
import * as Gradient from 'components/gradient'
import * as Icon from 'components/icon'
import { ReactComponent as SwapIcon } from 'assets/icons/swap.svg'
import Success from '../common/success'
import Rejected from '../common/rejected'
import Rate from '../rate'
import Sending from './sending'
import styles from './styles.module.scss'

interface Props {
  detail: { tx: Tx; order: Order }
  onSendTx: (tx: Tx) => Promise<TransactionResponse>
  onRefresh: () => Promise<{ tx: Tx; order: Order }>
  onClose: () => any
  onUpdate: (props: ModalUpdateProps) => void
}

const Review: FC<Props> = ({
  detail,
  onSendTx,
  onRefresh,
  onClose,
  onUpdate
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [tx, setTx] = useState(detail.tx)
  const [order, setOrder] = useState(detail.order)
  const { input, output, price } = useMemo(() => {
    const { price } = order

    return {
      input: CurrencyAmount.fromRawAmount(
        price.baseCurrency,
        price.denominator
      ),
      output: CurrencyAmount.fromRawAmount(
        price.quoteCurrency,
        price.numerator
      ),
      price
    }
  }, [order])
  const { price: inputFaitPrice } = useFaitPrice(input)
  const { price: outputFaitPrice } = useFaitPrice(output)
  const [effective, setEffective] = useState(50)
  const handleRefreshOrder = useCallback(() => {
    setLoading(true)
    onRefresh()
      .then(({ tx, order }) => {
        setTx(tx)
        setOrder(order)
        setEffective(50)
      })
      .catch(() => onClose())
      .finally(() => setLoading(false))
  }, [onRefresh, onClose])
  const handleSendTx = useCallback(() => {
    onUpdate({
      title: null,
      closable: false,
      children: <Sending price={price} />
    })
    onSendTx(tx)
      .then(({ chainId, hash, from }) => {
        dispatch(
          add({
            account: from as string,
            chainId: chainId as number,
            tx: {
              type: TYPE_SWAP,
              txid: hash,
              createdAt: Date().toString(),
              inputToken: input.currency.toJSON(),
              outputToken: output.currency.toJSON(),
              inputAmount: input.toFixed(4),
              outputAmount: output.toFixed(4)
            }
          })
        )
        onUpdate({
          closable: true,
          children: (
            <Success chainId={chainId} txid={hash} onClose={onClose}>
              <Trans>Transaction Submitted</Trans>
            </Success>
          )
        })
      })
      .catch(err => {
        console.error(err)
        onUpdate({
          closable: true,
          children: (
            <Rejected onClose={onClose}>
              <Trans>Transaction rejected.</Trans>
            </Rejected>
          )
        })
      })
  }, [onSendTx, tx, price, input, output, onUpdate, onClose, dispatch])

  useEffect(() => {
    if (!effective) return
    const timer = setTimeout(() => {
      setEffective(effective - 1)
    }, 1000)

    return () => clearTimeout(timer)
  }, [effective])

  return (
    <div className={styles.order}>
      <div className={styles.info}>
        <div className={styles.item}>
          <Icon.Currency className={styles.icon} currency={input.currency} />
          <div className={styles.coin}>
            <h4>
              <Trans>You Pay</Trans>
            </h4>
            <p>{input.currency.symbol}</p>
          </div>
          <div className={styles.amount}>
            <h4>{new BigNumber(input.toFixed() || '0').toFormat(6)}</h4>
            <p>
              {inputFaitPrice
                ? `≈ $${new BigNumber(inputFaitPrice).dp(3).toFormat()}`
                : '-'}
            </p>
          </div>
        </div>
        <SwapIcon className={styles.swap} />
        <hr className={styles.line} />
        <div className={styles.item}>
          <Icon.Currency className={styles.icon} currency={output.currency} />
          <div className={styles.coin}>
            <h4>
              <Trans>You Receive</Trans>
            </h4>
            <p>{output.currency.symbol}</p>
          </div>
          <div className={styles.amount}>
            <h4>{new BigNumber(output.toFixed()).toFormat(6)}</h4>
            <p>
              {outputFaitPrice
                ? `≈ $${new BigNumber(outputFaitPrice).dp(3).toFormat()}`
                : '-'}
              {inputFaitPrice && outputFaitPrice && (
                <span>
                  {new BigNumber(outputFaitPrice)
                    .minus(inputFaitPrice)
                    .div(inputFaitPrice)
                    .dp(3)
                    .toFormat()}
                  %
                </span>
              )}
            </p>
          </div>
        </div>
      </div>
      <Rate price={price} />
      <footer className={styles.footer}>
        {effective ? (
          <Button block size="large" onClick={handleSendTx}>
            <Gradient.Text>
              <Trans>Place Order - {effective}s</Trans>
            </Gradient.Text>
          </Button>
        ) : (
          <Button
            block
            size="large"
            loading={loading}
            onClick={handleRefreshOrder}
          >
            {loading ? (
              <span>
                <Trans>Requoting...</Trans>
              </span>
            ) : (
              <Gradient.Text>
                <Trans>Requote</Trans>
              </Gradient.Text>
            )}
          </Button>
        )}
      </footer>
    </div>
  )
}

export default Review
