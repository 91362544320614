import { ReactChild, FC } from 'react'
import cls from 'classnames'
import styles from './styles.module.scss'

interface Props {
  name: string
  value: string
  checked: boolean
  onChange: (value: string) => void
  children?: ReactChild
}

const Checkbox: FC<Props> = ({
  name,
  value,
  checked,
  onChange,
  children
}: Props) => (
  <label className={styles.container} htmlFor={value}>
    <span className={cls(styles.checkbox, { [styles.checked]: checked })}>
      <input
        id={value}
        type="checkbox"
        checked={checked}
        name={name}
        onChange={() => onChange(value)}
      />
    </span>
    <span className={styles.text}>{children}</span>
  </label>
)

export default Checkbox
