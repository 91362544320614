import { useState, useEffect, useContext, useMemo } from 'react'
import { BigNumber, FixedNumber } from '@ethersproject/bignumber'
import {
  NATIVE_CURRENCY_ADDRESS,
  ID,
  Currency,
  providers
} from '@moverfinance/dex-sdk'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import { Context } from 'components/balance'
import { useUserWeb3 } from 'hooks/useWeb3'
import { useMulticallContract, useERC20Contract } from 'hooks/useContract'

let timer: NodeJS.Timer | null = null

export const useBalances = (addresses: { [chainId: string]: string[] }) => {
  const { account } = useUserWeb3()
  const erc20 = useERC20Contract()
  const multicall = useMulticallContract()
  const [balances, setBalances] = useState<{
    [chainId: number]: { [address: string]: string }
  }>({})
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!account || !Object.keys(addresses).length) return

    setError(null)
    setLoading(true)
    timer = setTimeout(() => {
      Promise.all(
        Object.entries(addresses)
          .filter(([, items]) => items.length)
          .map(([chainId, items]) => {
            const _chainId = chainId as unknown as ID
            const _multicall = multicall(_chainId)

            _multicall
              .connect(providers[_chainId])
              .callStatic.aggregate(
                items.map(address => {
                  if (address === NATIVE_CURRENCY_ADDRESS) {
                    const data = _multicall.interface.encodeFunctionData(
                      'getEthBalance',
                      [account]
                    )

                    return { target: _multicall.address, callData: data }
                  } else {
                    const erc20Contract = erc20(address)
                    const data = erc20Contract.interface.encodeFunctionData(
                      'balanceOf',
                      [account]
                    )

                    return { target: address, callData: data }
                  }
                })
              )
              .then(({ returnData }) => {
                setBalances(balances =>
                  cloneDeep(
                    merge(balances, {
                      [chainId]: Object.fromEntries(
                        returnData.map((balance, i) => [
                          items[i],
                          BigNumber.from(balance).toString()
                        ])
                      )
                    })
                  )
                )
              })
          })
      )
        .catch(err => setError(err))
        .finally(() => setLoading(false))
    }, 2000)

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [addresses, erc20, multicall, account])

  return { balances, loading, error }
}

export const useBalance = (currency: Currency | null) => {
  const { balances, add, remove } = useContext(Context)
  const balance = useMemo(
    () => currency && balances?.[currency.chainId]?.[currency.address],
    [balances, currency]
  )

  useEffect(() => {
    if (currency) add(currency)
    return () => {
      if (currency) remove(currency)
    }
  }, [currency, add, remove])

  return (
    balance &&
    currency &&
    FixedNumber.fromValue(BigNumber.from(balance), currency.decimals).toString()
  )
}

export default useBalance
