import { useState, useCallback, FC } from 'react'
import { Trans } from '@lingui/macro'
import cls from 'classnames'
import Modal from 'components/modal'
import styles from './styles.module.scss'

const Popup: FC = props => {
  const { children } = props
  const [visible, setVisible] = useState(false)
  const handleSetVisible = useCallback(
    () => setVisible(!visible),
    [visible, setVisible]
  )

  return (
    <>
      <div
        className={cls(styles.switch, styles.mobile)}
        onClick={handleSetVisible}
      />
      <Modal
        title={<Trans>Transaction Settings</Trans>}
        visible={visible}
        onClose={setVisible}
      >
        <div style={{ height: '100%' }}>{children}</div>
      </Modal>
    </>
  )
}

export default Popup
