import { ID, Currency, NATIVE_CURRENCY_ADDRESS } from '@moverfinance/dex-sdk'
import { CHAIN_INFO } from 'constants/chains'

export const NATIVE_CURRENCIES: { [key in ID]: Currency } = {
  [ID.MAINNET]: Currency.from({
    chainId: ID.MAINNET,
    address: NATIVE_CURRENCY_ADDRESS,
    ...CHAIN_INFO[ID.MAINNET].nativeCurrency
  }),
  [ID.ROPSTEN]: Currency.from({
    chainId: ID.ROPSTEN,
    address: NATIVE_CURRENCY_ADDRESS,
    ...CHAIN_INFO[ID.ROPSTEN].nativeCurrency
  }),
  [ID.KOVAN]: Currency.from({
    chainId: ID.KOVAN,
    address: NATIVE_CURRENCY_ADDRESS,
    ...CHAIN_INFO[ID.KOVAN].nativeCurrency
  }),
  [ID.BSC]: Currency.from({
    chainId: ID.BSC,
    address: NATIVE_CURRENCY_ADDRESS,
    ...CHAIN_INFO[ID.BSC].nativeCurrency
  }),
  [ID.POLYGON]: Currency.from({
    chainId: ID.POLYGON,
    address: NATIVE_CURRENCY_ADDRESS,
    ...CHAIN_INFO[ID.POLYGON].nativeCurrency
  }),
  [ID.FANTOM]: Currency.from({
    chainId: ID.FANTOM,
    address: NATIVE_CURRENCY_ADDRESS,
    ...CHAIN_INFO[ID.FANTOM].nativeCurrency
  }),
  [ID.AVALANCHE]: Currency.from({
    chainId: ID.AVALANCHE,
    address: NATIVE_CURRENCY_ADDRESS,
    ...CHAIN_INFO[ID.AVALANCHE].nativeCurrency
  })
}

export const COMMON_CURRENCIES: { [key in ID]: string[] } = {
  [ID.MAINNET]: [
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', // WETH
    '0xdac17f958d2ee523a2206206994597c13d831ec7', // USDT
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48', // USDC
    '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599', // WBTC
    '0x6b175474e89094c44da98b954eedeac495271d0f' // DAI
  ],
  [ID.ROPSTEN]: [
    '0xc778417e063141139fce010982780140aa0cd5ab', // WETH
    '0x07865c6e87b9f70255377e024ace6630c1eaa37f' // USDC
  ],
  [ID.KOVAN]: [
    '0x4F96Fe3b7A6Cf9725f59d353F723c1bDb64CA6Aa', // DAI
    '0x2d07793bccA759F2C7cFA20680973bA8f4783Afb', // USDC
    '0xd0a1e359811322d97991e03f863a0c30c2cf029c' // WETH
  ],
  [ID.BSC]: [
    '0x2170ed0880ac9a755fd29b2688956bd959f933f8', // ETH
    '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', // WBNB
    '0x55d398326f99059ff775485246999027b3197955', // USDT
    '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d', // USDC
    '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3' // DAI
  ],
  [ID.POLYGON]: [
    '0xc2132d05d31c914a87c6611c10748aeb04b58e8f', // USDT
    '0x2791bca1f2de4661ed88a30c99a7a9449aa84174', // USDC
    '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270', // WMATIC
    '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6', // WBTC
    '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063' // DAI
  ],
  [ID.FANTOM]: [
    '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83', // WFTM
    '0x74b23882a30290451a17c44f4f05243b6b58c76d', // WETH
    '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e', // DAI
    '0x04068da6c83afcfa0e13ba15a6696662335d5b75', // USDC
    '0x321162cd933e2be498cd2267a90534a804051b11' // WBTC
  ],
  [ID.AVALANCHE]: [
    '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab', // WETH
    '0xc7198437980c041c805a1edcba50c1ce5db95118', // USDT
    '0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664', // USDC
    '0x50b7545627a5162f82a992c33b87adc75187b218', // WBTC
    '0xd586e7f844cea2f87f50152665bcbc2c279d8d70' // DAI
  ]
}

export const RECOMMEND_CURRENCIES: { [key in ID]: string[] } = {
  [ID.MAINNET]: [
    ...COMMON_CURRENCIES[ID.MAINNET],
    '0xb8c77482e45f1f44de1745f52c74426c631bdd52', // BNB
    '0xbb0e17ef65f82ab018d8edd776e8dd940327b28b', // AXS
    '0x514910771af9ca656af840dff83e8264ecf986ca', // LINK
    '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0', // MATIC
    '0x4fabb145d64652a948d72533023f6e7a623c7c53', // BUSD
    '0xc944e90c64b2c07662a292be6244bdf05cda44a7', // GRT
    '0x3845badade8e6dff049820680d1f14bd3903a5d0', // SAND
    '0x0f5d2fb29fb7d3cfee444a200298f468908cc942', // MANA
    '0xd533a949740bb3306d119cc777fa900ba034cd52', // CRA
    '0x25f8087ead173b73d6e8b84329989a8eea16cf73', // YGG
    '0x111111111117dc0aa78b770fa6a738034120c302', // 1INCH
    '0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9', // AAVE
    '0x3506424f91fd33084466f402d5d97f05f8e3b4af', // CHZ
    '0xbbbbca6a901c926f240b89eacb641d8aec7aeafd', // LRC
    '0xc00e94cb662c3520282e6f5717214004a7f26888' // COMP
  ],
  [ID.ROPSTEN]: [...COMMON_CURRENCIES[ID.ROPSTEN]],
  [ID.KOVAN]: [...COMMON_CURRENCIES[ID.KOVAN]],
  [ID.BSC]: [
    ...COMMON_CURRENCIES[ID.BSC],
    '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd', // LINK
    '0xe9e7cea3dedca5984780bafc599bd69add087d56', // BUSD
    '0x52ce071bd9b1c4b00a0b92d298c512478cad67e8', // COMP
    '0x947950bcc74888a40ffa2593c5798f11fc9124c4', // SUSHI
    '0xa2b726b1145a4773f68593cf171187d8ebe4d495', // INJ
    '0x658a109c5900bc6d2357c87549b651670e5b0539', // FOR
    '0x71de20e0c4616e7fcbfdd3f875d568492cbe4739', // SWINGBY
    '0x889efce29fa0bb9b26be9fda17a8003f4e8da4de', // EGG
    '0x8443f091997f06a61670b735ed92734f5628692f', // BEL
    '0x039cb485212f996a9dbb85a9a75d898f94d38da6', // DEXE
    '0x0eb3a705fc54725037cc9e008bdede697f62f335', // ATOM
    '0x728c5bac3c3e370e372fc4671f9ef6916b814d8b', // UNFI
    '0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2', // DODO
    '0x44754455564474a89358b2c2265883df993b12f0', // ZEE
    '0x7af173f350d916358af3e218bdf2178494beb748' // TRADE
  ],
  [ID.POLYGON]: [
    ...COMMON_CURRENCIES[ID.POLYGON],
    '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619', // ETH
    '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39', // LINK
    '0xd6df932a45c0f255f85145f286ea0b292b21c90b', // AAVE
    '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a', // SUSHI
    '0xb33eaad8d922b1083446dc23f610c2567fb5180f', // UNI
    '0x831753dd7087cac61ab5644b308642cc1c33dc13' // QUICK
  ],
  [ID.FANTOM]: [
    ...COMMON_CURRENCIES[ID.FANTOM],
    '0xd67de0e0a0fd7b15dc8348bb9be742f3c5850454', // WBNB
    '0x1e4f97b9f9f913c46f1632781732927b9019c68b', // CRV
    '0x6a07a792ab2965c72a5b8088d3a069a7ac3a993b', // AAVE
    '0x56ee926bd8c72b2d5fa1af4d9e4cbb515a1e3adc', // SNX
    '0xae75a438b2e0cb8bb01ec1e1e376de11d44477cc', // SUSHI
    '0x29b0da86e484e1c0029b56e817912d778ac0ec69', // YFI
    '0x46e7628e8b4350b2716ab470ee0ba1fa9e76c6c5', // BAND
    '0x753fbc5800a8c8e3fb6dc6415810d627a387dfc9', // BADGER
    '0xcf726a06f3dcec8ef2b033336d138caa0eae5af2', // RGT
    '0x0e1694483ebb3b74d3054e383840c6cf011e518e', // SUSD
    '0x657a1861c15a3ded9af0b6799a195a249ebdcbc6', // CREAM
    '0x5a2e451fb1b46fde7718315661013ae1ae68e28c', // CGS
    '0x477a9d5df9beda06f6b021136a2efe7be242fcc9', // TRAVA
    '0xfb98b335551a418cd0737375a2ea0ded62ea213b', // MIMATIC
    '0x8b8407c6184f1f0fd1082e83d6a3b8349caced12', // COVAL
    '0x25a528af62e56512a19ce8c3cab427807c28cc19' // DSLA
  ],
  [ID.AVALANCHE]: [
    ...COMMON_CURRENCIES[ID.AVALANCHE],
    '0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab', // WETH
    '0x264c1383ea520f73dd837f915ef3a732e204a493', // BNB
    '0x5947bb275c521040051d82396192181b413227a3', // LINK
    '0x19860ccb0a68fd4213ab9d8266f7bbf05a8dde98', // BUSD
    '0x8a0cac13c7da965a312f08ea4229c37869e85cb9', // GRT
    '0xd501281565bf7789224523144fe5d98e8b28f267', // 1INCH
    '0x63a72806098bd3d9520cc43356dd78afe5d386d9', // AAVE
    '0xc3048e19e76cb9a3aa9d77d8c03c29fc906e2437', // COMP
    '0x88128fd4b259552a9a1d457f435a6527aab72d42', // MKR
    '0x37b608519f91f70f2eeb0e5ed9af4061722e4f76', // SUSHI
    '0xbec243c995409e6520d7c41e404da5deba4b209b', // SNX
    '0x3bd2b1c7ed8d396dbb98ded3aebb41350a5b2339', // UMA
    '0x98443b96ea4b0858fdf3219cd13e98c7a4690588', // BAT
    '0x9eaac1b23d935365bd7b542fe22ceee2922f52dc', // YFI
    '0x596fa47043f99a4e0f122243b841e55375cde0d2', // ZRX
    '0x1c20e891bab6b1727d14da358fae2984ed9b59eb' // TUSD
  ]
}
