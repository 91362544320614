import OriginModal from './modal'
import open, { UpdateProps } from './open'

type ModalType = typeof OriginModal & { open: typeof open }

const Modal = OriginModal as ModalType

Modal.open = open

export type { UpdateProps }
export default Modal
