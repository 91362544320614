import { Trans } from '@lingui/macro'
import { ID } from '@moverfinance/dex-sdk'
import { TxApprove } from 'constants/tx'
import { useUserWeb3 } from 'hooks/useWeb3'
import { link, ExplorerDataType } from 'utils/explorer'
import { ReactComponent as Link } from 'assets/icons/link.svg'
import styles from './styles.module.scss'

interface Props {
  tx: TxApprove
}

const Approve = ({ tx }: Props) => {
  const { txid } = tx
  const { chainId } = useUserWeb3()

  return (
    <>
      <span>
        <Trans>
          Approve
          <strong>{tx.currency.symbol}</strong>
        </Trans>
      </span>
      <Link
        className={styles.link}
        onClick={() =>
          window.open(link(chainId as ID, txid, ExplorerDataType.TRANSACTION))
        }
      />
    </>
  )
}

export default Approve
