import { BigNumber } from '@ethersproject/bignumber'
import { hexStripZeros } from '@ethersproject/bytes'
import { Web3Provider } from '@ethersproject/providers'
import { ID } from '@moverfinance/dex-sdk'
import { CHAIN_INFO } from 'constants/chains'

interface SwitchNetworkArguments {
  library: Web3Provider
  chainId: ID
}

interface AddNetworkArguments {
  library: Web3Provider
  chainId: ID
}

export const addNetwork = async ({ library, chainId }: AddNetworkArguments) => {
  const info = CHAIN_INFO[chainId]

  if (!library?.provider?.request) return
  const formattedChainId = hexStripZeros(BigNumber.from(chainId).toHexString())
  try {
    await library?.provider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: formattedChainId,
          chainName: info.label,
          rpcUrls: info.rpcUrls,
          nativeCurrency: info.nativeCurrency,
          blockExplorerUrls: [info.explorer]
        }
      ]
    })
  } catch (error) {
    console.error('error add eth network: ', chainId, info, error)
  }
}

export const switchToNetwork = async ({
  library,
  chainId
}: SwitchNetworkArguments) => {
  if (!library?.provider?.request) return
  const formattedChainId = hexStripZeros(BigNumber.from(chainId).toHexString())
  try {
    await library?.provider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: formattedChainId }]
    })
  } catch (error) {
    // 4902 is the error code for attempting to switch to an unrecognized chainId
    if (error.code === 4902 && chainId !== undefined) {
      // metamask (only known implementer) automatically switches after a network is added
      // the second call is done here because that behavior is not a part of the spec and cannot be relied upon in the future
      // metamask's behavior when switching to the current network is just to return null (a no-op)
      await addNetwork({ library, chainId })
      await switchToNetwork({ library, chainId })
    } else {
      console.error('error switch eth network: ', chainId, error)
    }
  }
}
