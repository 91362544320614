import { Trans } from '@lingui/macro'
import { TxSwap } from 'constants/tx'
import { ID } from '@moverfinance/dex-sdk'
import { useUserWeb3 } from 'hooks/useWeb3'
import { link, ExplorerDataType } from 'utils/explorer'
import { ReactComponent as Link } from 'assets/icons/link.svg'
import styles from './styles.module.scss'

interface Props {
  tx: TxSwap
}

const Swap = ({ tx }: Props) => {
  const { inputToken, outputToken, inputAmount, outputAmount, txid } = tx
  const { chainId } = useUserWeb3()

  return (
    <>
      <span>
        <Trans>
          Swap
          <strong>
            {inputAmount} {inputToken.symbol ?? 'UNKNOWN'}
          </strong>
          to
          <strong>
            {outputAmount} {outputToken.symbol ?? 'UNKNOWN'}
          </strong>
        </Trans>
      </span>
      <Link
        className={styles.link}
        onClick={() =>
          window.open(link(chainId as ID, txid, ExplorerDataType.TRANSACTION))
        }
      />
    </>
  )
}

export default Swap
