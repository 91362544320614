import { useCallback, FC } from 'react'
import { Trans } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { MaxUint256 } from '@ethersproject/constants'
import { Currency } from '@moverfinance/dex-sdk'
import useApprove from 'hooks/useApprove'
import Modal from 'components/modal'
import Button from 'components/button'
import * as Gradient from 'components/gradient'
import * as Icon from 'components/icon'
import Success from '../common/success'
import Rejected from '../common/rejected'
import styles from './styles.module.scss'

interface Props {
  currency: Currency
  amount?: string
  spender: string
}

const Approve: FC<Props> = ({ currency, amount = MaxUint256, spender }) => {
  const { i18n } = useLingui()
  const approve = useApprove(currency)
  const handleApprove = useCallback(() => {
    const modal = Modal.open(
      {
        title: null,
        closable: false,
        children: (
          <div className={styles.modal}>
            <Icon.Currency className={styles.icon} currency={currency} />
            <p>
              <Trans>Please confirm the allowance approval</Trans>
            </p>
          </div>
        )
      },
      i18n
    )

    approve(amount, spender)
      .then(({ chainId, hash }) => {
        modal.update({
          closable: true,
          children: (
            <Success
              chainId={chainId}
              txid={hash}
              onClose={() => modal.close()}
            >
              <Trans>Approve Submitted</Trans>
            </Success>
          )
        })
      })
      .catch(err => {
        console.error(err)
        modal.update({
          closable: true,
          children: (
            <Rejected onClose={() => modal.close()}>
              <Trans>Approve rejected.</Trans>
            </Rejected>
          )
        })
      })
  }, [currency, i18n, amount, spender, approve])

  return (
    <Button size="large" block onClick={handleApprove}>
      <Gradient.Text>
        <Trans>
          We need permission to use <br /> your {currency.symbol}
        </Trans>
      </Gradient.Text>
    </Button>
  )
}

export default Approve
