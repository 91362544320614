import React from 'react'
import ReactDOM from 'react-dom'
import { Provider as StoreProvider } from 'store'
import ErrorBoundary from 'components/error-boundary'
import ThemeProvider from 'components/theme'
import I18nProvider from 'components/i18n'
import Web3Provider from 'components/web3'
import Init from 'components/init'
import Router from 'router'
import reportWebVitals from './reportWebVitals'
import 'styles/globals.scss'

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <ThemeProvider>
        <I18nProvider>
          <StoreProvider>
            <Web3Provider>
              <Init>
                <Router />
              </Init>
            </Web3Provider>
          </StoreProvider>
        </I18nProvider>
      </ThemeProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
