import { FC } from 'react'
import { Link } from 'react-router-dom'
import cls from 'classnames'
import { Trans } from '@lingui/macro'
import styles from './styles.module.scss'

interface Props {
  className?: string
}

const Footer: FC<Props> = ({ className }) => (
  <footer className={cls(styles.footer, className)}>
    <span className={styles.copyright}>©{new Date().getFullYear()} Woven.</span>
    <div className={styles.links}>
      <Link to="/privacy">
        <Trans>Privacy</Trans>
      </Link>
      <Link to="/terms">
        <Trans>Terms</Trans>
      </Link>
    </div>
  </footer>
)

export default Footer
