import { FC } from 'react'
import cls from 'classnames'
import styles from './styles.module.scss'

interface Props {
  value: string | boolean | number
  theme?: 'light'
  size?: 'small'
  onChange: (value: string | boolean | number) => void
  items: Array<{
    value: string | boolean | number
    label: string
  }>
}

const Tabs: FC<Props> = ({ value: active, theme, size, items, onChange }) => (
  <div
    className={cls(styles.tabs, {
      [styles[theme as string]]: !!theme,
      [styles[size as string]]: !!size
    })}
  >
    {items.map(({ value, label }, i) => (
      <button
        className={cls(styles.item, {
          [styles.active]: value === active
        })}
        onClick={() => onChange(value)}
        key={i}
      >
        {label}
      </button>
    ))}
  </div>
)

export default Tabs
