import { useEffect, useState } from 'react'
import { ID, Currency } from '@moverfinance/dex-sdk'
import { NATIVE_CURRENCIES } from 'constants/currency'
import { useActiveWeb3 } from 'hooks/useWeb3'
import History from './history'
import About from './about'
import Setting from './setting'
import Panel from './panel'

const Swap = () => {
  const { chainId } = useActiveWeb3()
  const [input, setInput] = useState<Currency | null>(
    NATIVE_CURRENCIES[chainId as ID]
  )
  const [output, setOutput] = useState<Currency | null>(null)

  useEffect(() => {
    setInput(NATIVE_CURRENCIES[chainId as ID])
    setOutput(null)
  }, [chainId])

  return (
    <Panel
      input={input}
      output={output}
      setting={<Setting />}
      onInputChange={currency => setInput(currency)}
      onOutputChange={currency => setOutput(currency)}
    >
      <History
        input={input}
        output={output}
        onInputChange={address => setInput(address)}
        onOutputChange={address => setOutput(address)}
      />
      <About input={input} output={output} />
    </Panel>
  )
}

export default Swap
