import { useEffect, FC } from 'react'
import { Trans } from '@lingui/macro'
import {
  useUserWeb3,
  useNetworkWeb3,
  useEagerConnect,
  useInactiveListener
} from 'hooks/useWeb3'
import network from 'connectors/network'
import BlockNumber from './block-number'

const Manager: FC = ({ children }) => {
  const { active } = useUserWeb3()
  const {
    active: networkActive,
    error: networkError,
    activate: activateNetwork
  } = useNetworkWeb3()
  // try to eagerly connect to an injected provider, if it exists and has granted access already
  const triedEager = useEagerConnect()

  // after eagerly trying injected, if the network connect ever isn't active or in an error state, activate itd
  useEffect(() => {
    if (triedEager && !networkActive && !networkError && !active) {
      activateNetwork(network)
    }
  }, [triedEager, networkActive, networkError, activateNetwork, active])

  // fix change network to avalanche
  useEffect(() => {
    if (window.ethereum && window.ethereum.removeAllListeners) {
      window.ethereum.removeAllListeners(['networkChanged'])
    }
  }, [])

  // when there's no account connected, react to logins (broadly speaking) on the injected provider, if it exists
  useInactiveListener(!triedEager)

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (triedEager && !active && networkError) {
    return (
      <div>
        <Trans>
          Oops! An unknown error occurred. Please refresh the page, or visit
          from another browser or device.
        </Trans>
      </div>
    )
  }

  return <BlockNumber>{children}</BlockNumber>
}

export default Manager
