import { ID } from '@moverfinance/dex-sdk'
import { CHAIN_INFO } from 'constants/chains'

export enum ExplorerDataType {
  TRANSACTION = 'transaction',
  TOKEN = 'token',
  ADDRESS = 'address',
  BLOCK = 'block'
}

export const link = (chainId: ID, data: string, type: ExplorerDataType) => {
  const { explorer } = CHAIN_INFO[chainId]

  switch (type) {
    case ExplorerDataType.TRANSACTION:
      return `${explorer}tx/${data}`
    case ExplorerDataType.ADDRESS:
    case ExplorerDataType.TOKEN:
      return `${explorer}address/${data}`
    case ExplorerDataType.BLOCK:
      return `${explorer}block/${data}`
    default:
      return explorer
  }
}
