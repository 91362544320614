import { FC } from 'react'
import cls from 'classnames'
import { Currency } from '@moverfinance/dex-sdk'
import NetworkIcon from './network'
import styles from './styles.module.scss'

interface Props {
  currency?: Currency | null
  className?: string
  black?: boolean
  network?: boolean
}

const Icon: FC<Props> = ({ currency, className, network = true }) => {
  const src = currency
    ? `${process.env.REACT_APP_DEX_SERVER}/static/currency/${currency.symbol}`
    : `${process.env.REACT_APP_DEX_SERVER}/default/currency.png`

  return (
    <div className={cls(styles.currency, className)}>
      <img src={src} />
      {network && currency && (
        <div className={styles.network}>
          <NetworkIcon id={currency.chainId} color />
        </div>
      )}
    </div>
  )
}

export default Icon
