import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import cls from 'classnames'
import Header from 'components/header'
import Footer from 'components/footer'
import Button from 'components/button'
import * as Gradient from 'components/gradient'
import styles from './styles.module.scss'

const Home = () => (
  <div className={styles.bg}>
    <div className={styles.home}>
      <style
        dangerouslySetInnerHTML={{ __html: `html, body { background: #fff; }` }}
      />
      <Header className={styles.header} />
      <div className={cls(styles.context, styles.main)}>
        <div className={styles.text}>
          <h2>
            <Trans>Multi-Chain Trading Infrastructure</Trans>
          </h2>
          <p>
            <Trans>Find the best route for your cross-chain trading.</Trans>
          </p>
          <Link to="/swap" className={styles.link}>
            <Button>
              <Gradient.Text>
                <Trans>Woven DEX</Trans>
              </Gradient.Text>
            </Button>
          </Link>
          <p style={{ marginTop: 36 }}>
            <Trans>Visualize the smart money movement.</Trans>
          </p>
          <Button>
            <Gradient.Text>
              <Trans>Coming Soon</Trans>
            </Gradient.Text>
          </Button>
        </div>
        <div className={styles.image} />
      </div>
      <Footer className={styles.footer} />
    </div>
  </div>
)

export default Home
