import cls from 'classnames'
import styles from './styles.module.scss'

interface Props {
  className?: string
}

const Loading = ({ className }: Props) => (
  <svg className={cls(styles.spinner, className)} viewBox="0 0 50 50">
    <defs>
      <linearGradient id="GradientLoading" className={styles.gradient}>
        <stop className={styles.color1} offset="0%" />
        <stop className={styles.color2} offset="100%" />
      </linearGradient>
    </defs>
    <circle
      className={styles.path}
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="5"
    ></circle>
  </svg>
)

export default Loading
