import { ID } from '@moverfinance/dex-sdk'
export interface L1ChainInfo {
  readonly blockWaitMsBeforeWarning?: number
  readonly explorer: string
  readonly label: string
  readonly chainId: ID
  readonly shortLabel: string
  readonly logoUrl?: string
  readonly rpcUrls?: string[]
  readonly nativeCurrency: {
    name: string // 'ETH',
    symbol: string // 'ETH',
    decimals: number //18,
  }
}

export interface L2ChainInfo extends L1ChainInfo {
  readonly bridge: string
  readonly logoUrl: string
  readonly statusPage?: string
}

export type ChainInfo = {
  readonly [chainId: number]: L1ChainInfo | L2ChainInfo
} & {
  readonly [chainId in SupportedL2ChainId]: L2ChainInfo
} & {
  readonly [chainId in SupportedL1ChainId]: L1ChainInfo
}

export type SupportedL1ChainId = typeof L1_IDS[number]

export type SupportedL2ChainId = typeof L2_IDS[number]

export type SupportedAllChainId = typeof ALL_IDS[number]

export const L1_IDS = [
  ID.MAINNET,
  ID.BSC,
  ID.POLYGON,
  ID.FANTOM,
  ID.AVALANCHE
] as const

export const L2_IDS = [] as const

export const ALL_IDS: ID[] = [...L1_IDS, ...L2_IDS]

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY

export const NETWORK_URLS: { [key in SupportedAllChainId]: string } = {
  [ID.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  [ID.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  [ID.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  [ID.BSC]: 'https://bsc-dataseed.binance.org',
  [ID.POLYGON]: 'https://polygon-rpc.com/',
  [ID.FANTOM]: 'https://rpc.ftm.tools',
  [ID.AVALANCHE]: 'https://api.avax.network/ext/bc/C/rpc'
}

export const CHAIN_INFO: ChainInfo = {
  [ID.MAINNET]: {
    explorer: 'https://etherscan.io/',
    label: 'Ethereum',
    shortLabel: 'ETH',
    chainId: ID.MAINNET,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  },
  [ID.ROPSTEN]: {
    explorer: 'https://ropsten.etherscan.io/',
    label: 'Ropsten',
    shortLabel: 'Ropsten',
    chainId: ID.ROPSTEN,
    nativeCurrency: { name: 'Ropsten ETH', symbol: 'ETH', decimals: 18 }
  },
  [ID.KOVAN]: {
    explorer: 'https://kovan.etherscan.io/',
    label: 'Kovan',
    shortLabel: 'Kovan',
    chainId: ID.KOVAN,
    nativeCurrency: { name: 'Kovan ETH', symbol: 'ETH', decimals: 18 }
  },
  [ID.BSC]: {
    explorer: 'https://bscscan.com/',
    label: 'BSC Mainnet',
    shortLabel: 'BSC',
    chainId: ID.BSC,
    rpcUrls: [NETWORK_URLS[ID.BSC]],
    nativeCurrency: { name: 'Binance Coin', symbol: 'BNB', decimals: 18 }
  },
  [ID.POLYGON]: {
    explorer: 'https://polygonscan.com/',
    label: 'Polygon',
    shortLabel: 'Polygon',
    chainId: ID.POLYGON,
    rpcUrls: [NETWORK_URLS[ID.POLYGON]],
    nativeCurrency: { name: 'Polygon', symbol: 'MATIC', decimals: 18 }
  },
  [ID.AVALANCHE]: {
    explorer: 'https://cchain.explorer.avax.network/',
    label: 'Avalanche',
    shortLabel: 'Avalanche',
    chainId: ID.AVALANCHE,
    rpcUrls: [NETWORK_URLS[ID.AVALANCHE]],
    nativeCurrency: { name: 'Avalanche', symbol: 'AVAX', decimals: 18 }
  },
  [ID.FANTOM]: {
    explorer: 'https://ftmscan.com/',
    label: 'Fantom',
    shortLabel: 'Fantom',
    chainId: ID.FANTOM,
    rpcUrls: [NETWORK_URLS[ID.FANTOM]],
    nativeCurrency: { name: 'FTM', symbol: 'FTM', decimals: 18 }
  }
}
