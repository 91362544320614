import { useState, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  useSelector,
  updateTxSpeed,
  updateSlippage,
  updateExcludedExchanges
} from 'store/setting'
import { TxSpeed, Exchange, SLIPPAGE_OPTIONS } from 'constants/setting'

export const useTxSpeed = (): [TxSpeed, (txSpeed: TxSpeed) => void] => {
  const dispatch = useDispatch()
  const txSpeed = useSelector<TxSpeed>(state => state.txSpeed)
  const setTxSpeed = useCallback(
    (txSpeed: TxSpeed) => {
      dispatch(updateTxSpeed(txSpeed))
    },
    [dispatch]
  )

  return [txSpeed, setTxSpeed]
}

export const useExcludedExchanges = (): [
  Exchange[],
  (exchange: Exchange) => void
] => {
  const dispatch = useDispatch()
  const excludedExchanges = useSelector<Exchange[]>(
    state => state.excludedExchanges
  )
  const setExcludedExchanges = useCallback(
    (exchange: Exchange) => {
      if (excludedExchanges.includes(exchange)) {
        dispatch(
          updateExcludedExchanges(
            excludedExchanges.filter(
              excludedExchange => excludedExchange !== exchange
            )
          )
        )
      } else {
        dispatch(updateExcludedExchanges([...excludedExchanges, exchange]))
      }
    },
    [dispatch, excludedExchanges]
  )

  return [excludedExchanges, setExcludedExchanges]
}

export const useSlippage = (): [number, (slippage: number) => void] => {
  const dispatch = useDispatch()
  const slippage = useSelector<number>(state => state.slippage)
  const setSlippage = useCallback(
    (slippage: number) => {
      if (slippage >= 100 || slippage <= 0 || !slippage) return
      dispatch(updateSlippage(slippage))
    },
    [dispatch]
  )

  return [slippage, setSlippage]
}

export const useSlippageInput = (): [
  string,
  (value: string) => void,
  (value: string) => void
] => {
  const [slippage, setSlippage] = useSlippage()
  const [value, setValue] = useState(slippage.toString())
  const handleInputBlur = useCallback(
    (value: string) => {
      const num = parseFloat(value)

      setSlippage(num)
      if (SLIPPAGE_OPTIONS.includes(num) || num >= 100 || num <= 0) {
        setValue('')
      } else if (!value && !SLIPPAGE_OPTIONS.includes(slippage)) {
        setValue(slippage.toString())
      }
    },
    [slippage, setSlippage]
  )

  useEffect(() => {
    if (SLIPPAGE_OPTIONS.includes(slippage)) {
      setValue('')
    }
  }, [slippage])

  return [value, setValue, handleInputBlur]
}
