import { useState, useMemo, useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import axios, { CancelToken } from 'axios'
import { ID, NATIVE_CURRENCY_ADDRESS, Currency } from '@moverfinance/dex-sdk'
import { COINGECKO_IDS, WRAPPED_ADDRESSED } from 'constants/coingecko'
import { UnsupportedChainIdError } from 'errors/web3'
import { ExternalServerError } from 'errors/http'

type HistoryItem = [number, number]

export const useTradeHistory = (
  input: Currency | null,
  output: Currency | null,
  days = 1,
  interval = 15
) => {
  const dates = useMemo(
    () =>
      Array.from(new Array(days * 24 * (60 / interval)), (_, index) =>
        dayjs()
          .clone()
          .subtract(index * interval, 'm')
      ).reverse(),
    [days, interval]
  )
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [history, setHistory] = useState<HistoryItem[]>([])
  const getHistory = useCallback(
    (currency: Currency, cancelToken: CancelToken) => {
      const id = COINGECKO_IDS[currency.chainId as ID]
      if (!id) {
        return Promise.reject(
          new UnsupportedChainIdError(currency.chainId as number)
        )
      }
      const from = dates[0]
      const to = dates[dates.length - 1]
      const address =
        currency.address === NATIVE_CURRENCY_ADDRESS
          ? WRAPPED_ADDRESSED[currency.chainId as ID]
          : currency.address
      const url = `https://api.coingecko.com/api/v3/coins/${id}/contract/${address?.toLocaleLowerCase()}/market_chart/range?vs_currency=usd&from=${from.unix()}&to=${to.unix()}`

      return axios
        .get<{ prices: HistoryItem[] }>(url, { cancelToken })
        .then(res => {
          const { data } = res
          const { prices = [] } = data
          if (!prices.length) {
            return Promise.reject(new ExternalServerError(url))
          }
          const result = dates.reduce<{ [ts: string]: number }>((res, date) => {
            const ts = date.valueOf()
            const [, price] = prices.reduce(
              (res, item) => {
                if (Math.abs(item[0] - ts) < Math.abs(res[0] - ts)) return item
                return res
              },
              [0, 0]
            )

            res[ts] = price

            return res
          }, {})

          return result
        })
        .catch(error =>
          Promise.reject(new ExternalServerError(url, error?.response?.data))
        )
    },
    [dates]
  )

  useEffect(() => {
    if (!input && !output) return
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    const request =
      input && output
        ? Promise.all([
            getHistory(input, source.token),
            getHistory(output, source.token)
          ]).then(([input, output]) =>
            Object.entries(input).map<HistoryItem>(([ts, price]) => [
              parseInt(ts),
              price / (output[ts] || 0)
            ])
          )
        : getHistory((input || output) as Currency, source.token).then(prices =>
            Object.entries(prices).map<HistoryItem>(([ts, price]) => [
              parseInt(ts),
              price
            ])
          )

    setLoading(true)
    setError(null)
    request
      .then(history => setHistory(history))
      .catch(err => {
        setError(err)
        console.error(err)
      })
      .finally(() => setLoading(false))
    return () => source.cancel('get trade history operation canceled')
  }, [input, output, getHistory])

  return {
    history,
    loading,
    error
  }
}

export default useTradeHistory
