import { createContext, FC } from 'react'
import { useListener } from 'hooks/useGasPrice'
import { DEFAULT_GAS_PRICES } from 'constants/setting'

export const Context = createContext<{
  gasPrices: typeof DEFAULT_GAS_PRICES
  loading: boolean
  error: Error | null
}>({
  gasPrices: DEFAULT_GAS_PRICES,
  loading: true,
  error: null
})

const Gas: FC = ({ children }) => {
  const value = useListener()

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default Gas
