import { createReducer } from '@reduxjs/toolkit'
import { TxApprove, TxSwap } from 'constants/tx'
import { add, clear } from './actions'

export const initialState: {
  [chainIdAndAccount: string]: Array<TxApprove | TxSwap>
} = {}
export default createReducer(initialState, builder => {
  builder
    .addCase(add, (state, action) => {
      const { chainId, account, tx } = action.payload

      state[chainId + account] = [tx, ...(state[chainId + account] ?? [])]
    })
    .addCase(clear, (state, action) => {
      const { chainId, account } = action.payload

      state[chainId + account] = []
    })
})
