export class UnsupportedChainIdError extends Error {
  constructor(chainId: number) {
    super(`[Web3 Error]: Unsupported chain id '${chainId}'`)
  }
}

export class InvalidAddressError extends Error {
  constructor(address: string) {
    super(`[Web3 Error]: Invalid address '${address}'`)
  }
}

export class LibraryNotExistError extends Error {
  constructor() {
    super('[Web3 Error]: Library is not exist')
  }
}

export class WalletNotConnectedError extends Error {
  constructor() {
    super(`[Web3 Error]: Wallet not connected`)
  }
}
