import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import { ID } from '@moverfinance/dex-sdk'
import { ReactComponent as SubmittedIcon } from 'assets/icons/submitted.svg'
import Button from 'components/button'
import { link, ExplorerDataType } from 'utils/explorer'
import styles from './styles.module.scss'

interface Props {
  chainId: ID
  txid: string
  children: ReactElement
  onClose: () => void
}

const Success = ({ chainId, txid, children, onClose }: Props) => (
  <div className={styles.success}>
    <SubmittedIcon className={styles.icon} />
    <p className={styles.description}>{children}</p>
    <footer className={styles.footer}>
      <div>
        <Button
          block
          size="large"
          onClick={() =>
            window.open(link(chainId, txid, ExplorerDataType.TRANSACTION))
          }
        >
          <Trans>View on Explore</Trans>
        </Button>
      </div>
      <div className={styles.close}>
        <Button block line size="large" onClick={onClose}>
          <Trans>Close</Trans>
        </Button>
      </div>
    </footer>
  </div>
)

export default Success
