import { FC } from 'react'
import { configureStore } from '@reduxjs/toolkit'
import { Provider as ReduxProvider } from 'react-redux'
import { load, save } from 'redux-localstorage-simple'
import setting, { KEY as SETTING } from './setting'
import tx, { KEY as TX } from './tx'
import currency, { KEY as CURRENCY } from './currency'

const PERSISTED_KEYS = [SETTING, TX, CURRENCY]

const store = configureStore({
  reducer: {
    [SETTING]: setting,
    [TX]: tx,
    [CURRENCY]: currency
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      save({ namespace: 'woven', states: PERSISTED_KEYS, debounce: 1000 })
    ),
  preloadedState: load({
    namespace: 'woven',
    states: PERSISTED_KEYS,
    disableWarnings: process.env.NODE_ENV === 'test'
  })
})

export const Provider: FC = ({ children }) => (
  <ReduxProvider store={store}>{children}</ReduxProvider>
)
export type RootStore = ReturnType<typeof store.getState>
export default store
