import { useRef, FC, ReactElement } from 'react'
import cls from 'classnames'
import { CSSTransition } from 'react-transition-group'
import Portal from 'components/portal'
import Mask from 'components/mask'
import styles from './styles.module.scss'

export interface Props {
  children: ReactElement
  visible: boolean
  line?: boolean
  closable?: boolean
  onClose?: (visible: boolean) => void
  afterClose?: () => void
  title?: string | ReactElement | null
}

const Modal: FC<Props> = ({
  children,
  title,
  visible,
  line = true,
  closable = true,
  onClose,
  afterClose
}) => {
  const nodeRef = useRef<HTMLDivElement | null>(null)

  return (
    <Portal>
      <div>
        <Mask
          visible={visible}
          onClose={() => closable && onClose && onClose(false)}
        />
        <div className={styles.container}>
          <CSSTransition
            in={visible}
            nodeRef={nodeRef}
            unmountOnExit
            timeout={300}
            classNames={{
              enter: styles['modal-enter'],
              enterActive: styles['modal-enter-active'],
              exit: styles['modal-exit'],
              exitActive: styles['modal-exit-active']
            }}
            onExited={afterClose}
          >
            <div
              ref={nodeRef}
              className={cls(styles.modal, { [styles.line]: line })}
            >
              {closable && (
                <button
                  className={styles.close}
                  onClick={() => onClose && onClose(false)}
                />
              )}
              {title && <h3 className={styles.title}>{title}</h3>}
              <div className={styles.body}>{children}</div>
            </div>
          </CSSTransition>
        </div>
      </div>
    </Portal>
  )
}

export default Modal
