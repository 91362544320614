import { FC } from 'react'
import cls from 'classnames'
import { Trans } from '@lingui/macro'
import { Currency, Route } from '@moverfinance/dex-sdk'
import { CHAIN_INFO } from 'constants/chains'
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg'
import * as Icon from 'components/icon'
import styles from './styles.module.scss'

interface Props {
  input: Currency
  output: Currency
  route: Route
}

const RoutePanel: FC<Props> = ({ input, output, route }) => (
  <div className={styles.route}>
    <h3 className={styles.title}>
      <Trans>Best Route</Trans>
    </h3>
    <div className={styles.panel}>
      <div className={styles.from}>
        <Icon.Currency currency={input} />
        <p>
          <Trans>From</Trans>
        </p>
      </div>
      <div className={styles.steps}>
        {route.map((item, i) =>
          item.type === 'swap' ? (
            <div key={i} className={styles.item}>
              <header>
                <h4>{i + 1}</h4>
                <div className={styles.label}>
                  <span>{CHAIN_INFO[item.input.chainId]?.label}</span>
                  <Icon.Network
                    className={styles.icon}
                    id={item.input.chainId}
                    color
                  />
                </div>
              </header>
              <div className={styles.body}>
                <div className={styles.transform}>
                  <Icon.Currency
                    currency={item.input}
                    network={false}
                    className={styles.icon}
                  />
                  <Arrow className={styles.arrow} />
                  <Icon.Currency
                    currency={item.output}
                    network={false}
                    className={styles.icon}
                  />
                </div>
                <ul>
                  {item.data.map((exchange, j) => (
                    <li key={j}>
                      <span>{exchange.name.replace('_', ' ')}</span>
                      <span className={styles.proportion}>
                        {parseFloat(exchange.proportion) * 100}%
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <div key={i} className={cls(styles.item, styles.bridge)}>
              <header>
                <h4>{i + 1}</h4>
                <div className={styles.label}>
                  <span>
                    <Trans>Bridge</Trans>
                  </span>
                </div>
              </header>
              <div className={styles.body}>
                <div className={styles.transform}>
                  <Icon.Currency
                    currency={item.input}
                    network={false}
                    className={styles.icon}
                  />
                  <Arrow className={styles.arrow} />
                  <Icon.Currency
                    currency={item.output}
                    network={false}
                    className={styles.icon}
                  />
                </div>
                <ul>
                  <li>
                    <Icon.Bridge id={item.data} className={styles.icon} />
                    <span>{item.data}</span>
                  </li>
                </ul>
              </div>
            </div>
          )
        )}
      </div>
      <div className={styles.to}>
        <Icon.Currency currency={output} />
        <p>
          <Trans>To</Trans>
        </p>
      </div>
    </div>
  </div>
)

export default RoutePanel
