import { useState, ReactElement, useEffect } from 'react'
import * as ReactDOM from 'react-dom'
import { I18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import Web3Provider from 'components/web3'
import { Provider as StoreProvider } from 'store'
import Modal, { Props } from './modal'

export interface UpdateProps
  extends Omit<Props, 'visible' | 'onClose' | 'children'> {
  children?: ReactElement
}

const WrapModal = (props: Props & { visible: boolean }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(props.visible)
  }, [props.visible])

  return <Modal {...props} visible={visible} onClose={setVisible} />
}

function modal(props: Omit<Props, 'visible'>, i18n: I18n) {
  const container = document.createElement('div')
  let currentProps = {
    ...props,
    visible: true,
    afterClose: () => {
      ReactDOM.unmountComponentAtNode(container)
      document.body.removeChild(container)
    }
  }

  document.body.appendChild(container)

  function render(props: any) {
    ReactDOM.render(
      <Web3Provider>
        <I18nProvider i18n={i18n}>
          <StoreProvider>
            <WrapModal {...props} />
          </StoreProvider>
        </I18nProvider>
      </Web3Provider>,
      container
    )
  }

  function close() {
    currentProps = { ...currentProps, visible: false }

    render(currentProps)
  }

  function update(props: any = {}) {
    currentProps = { ...currentProps, ...props }

    render(currentProps)
  }

  render(currentProps)

  return { close, update }
}

export default modal
