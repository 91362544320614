import { useState, useMemo, useCallback } from 'react'
import { Trans } from '@lingui/macro'
import { ID } from '@moverfinance/dex-sdk'
import cls from 'classnames'
import { ALL_IDS, CHAIN_INFO } from 'constants/chains'
import { useActiveWeb3 } from 'hooks/useWeb3'
import injected from 'connectors/injected'
import network from 'connectors/network'
import { switchToNetwork } from 'utils/network'
import { ReactComponent as Arrow } from 'assets/icons/arrow.svg'
import * as Icon from '../icon'
import styles from './styles.module.scss'

const Network = () => {
  const [visible, setVisible] = useState(false)
  const { chainId, library, connector } = useActiveWeb3()
  const optianal = useMemo(
    () => connector === network || connector === injected,
    [connector]
  )
  const toggle = useCallback(
    (chainId: number) => {
      if (connector === network) {
        network.switch(chainId)
      } else if (connector === injected && library) {
        switchToNetwork({ library, chainId })
      }
      setVisible(false)
    },
    [connector, library]
  )

  if (!library) return null
  return (
    <div style={{ position: 'relative' }}>
      {visible && (
        <div className={styles.popup}>
          {ALL_IDS.map(id => {
            const info = CHAIN_INFO[id]

            return (
              <div
                className={cls(styles.item, {
                  [styles.active]: id === chainId
                })}
                key={id}
                onClick={() => id !== chainId && toggle(id)}
              >
                <Icon.Network color id={id} className={styles.icon} />
                <span>{info.label}</span>
              </div>
            )
          })}
        </div>
      )}
      <div
        className={cls(styles.network, { [styles.active]: visible })}
        onClick={() => setVisible(!visible)}
      >
        <Icon.Network id={chainId as ID} className={styles.icon} />
        <span className={styles.long}>
          {CHAIN_INFO[chainId as ID]?.label || <Trans>Chain {chainId}</Trans>}
        </span>
        <span className={styles.short}>
          {CHAIN_INFO[chainId as ID]?.shortLabel || (
            <Trans>Chain {chainId}</Trans>
          )}
        </span>
        {optianal && <Arrow className={styles.arrow} />}
      </div>
    </div>
  )
}

export { Icon }
export default Network
