import { useEffect, createContext, FC } from 'react'
import { i18n } from '@lingui/core'
import { I18nProvider as LinguiProvider } from '@lingui/react'
import { en, zh, PluralCategory } from 'make-plural/plurals'
import { SupportedLocale } from 'constants/locales'

type LocalePlural = {
  [key in SupportedLocale]: (
    n: number | string,
    ord?: boolean
  ) => PluralCategory
}

const plurals: LocalePlural = {
  'en-US': en,
  'zh-CN': zh
}

const activate = async (locale: SupportedLocale) => {
  const { messages } = await import(`@lingui/loader!locales/${locale}.po`)

  i18n.loadLocaleData(locale, { plurals: () => plurals[locale] })
  i18n.load(locale, messages)
  i18n.activate(locale)
  window.localStorage.setItem('locale', locale)
}

export const Context = createContext<(locale: SupportedLocale) => void>(
  locale => activate(locale)
)

const I18nProvider: FC = ({ children }) => {
  useEffect(() => {
    activate('en-US')
  }, [])

  return (
    <LinguiProvider forceRenderOnLocaleChange={false} i18n={i18n}>
      <Context.Provider value={locale => activate(locale)}>
        {children}
      </Context.Provider>
    </LinguiProvider>
  )
}

export default I18nProvider
