import { ID } from '@moverfinance/dex-sdk'

export const MULTICALL: { [key in ID]: string } = {
  [ID.MAINNET]: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
  [ID.ROPSTEN]: '0x53c43764255c17bd724f74c4ef150724ac50a3ed',
  [ID.KOVAN]: '0x2cc8688c5f75e365aaeeb4ea8d6a480405a48d2a',
  [ID.BSC]: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c',
  [ID.POLYGON]: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
  [ID.FANTOM]: '0x0118EF741097D0d3cc88e46233Da1e407d9ac139',
  [ID.AVALANCHE]: '0x4C211F45876d8EC7bAb54CAc0e32AAD15095358A'
}
