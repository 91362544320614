import { Component } from 'react'
import { Trans } from '@lingui/macro'

type ErrorBoundaryState = {
  error: Error | null
}

class ErrorBoundary extends Component<unknown, ErrorBoundaryState> {
  constructor(props: unknown) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { error }
  }

  render() {
    const { error } = this.state

    if (error) {
      return (
        <div>
          <Trans>ErrorBoundary</Trans>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
