export const TYPE_APPROVE = 'APPROVE'
export const TYPE_SWAP = 'SWAP'

export interface TxApprove {
  type: typeof TYPE_APPROVE
  txid: string
  createdAt: string
  currency: {
    chainId: number
    address: string
    decimals: number
    symbol: string
    name: string
  }
}

export interface TxSwap {
  type: typeof TYPE_SWAP
  txid: string
  createdAt: string
  inputToken: {
    chainId: number
    address: string
    decimals: number
    symbol: string
    name: string
  }
  outputToken: {
    chainId: number
    address: string
    decimals: number
    symbol: string
    name: string
  }
  inputAmount: string
  outputAmount: string
}
