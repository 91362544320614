import { useState, useCallback, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { Woven, Currency, Price, Typed } from '@moverfinance/dex-sdk'
import { useActiveWeb3 } from 'hooks/useWeb3'
import { useSlippage, useExcludedExchanges } from 'hooks/useSetting'

export interface Order {
  price: Price
}

export const useSwap = (
  input: Currency,
  output: Currency,
  amount: string,
  typed: Typed
) => {
  const { chainId, account } = useActiveWeb3()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [slippage] = useSlippage()
  const [excludedExchanges] = useExcludedExchanges()
  const woven = useMemo(() => {
    if (!input || !output) return null
    return new Woven(input, output)
  }, [input, output])
  const swap = useCallback(() => {
    if (
      !chainId ||
      !woven ||
      !input ||
      !output ||
      !account ||
      !amount ||
      new BigNumber(amount).isLessThanOrEqualTo('0')
    ) {
      return Promise.reject() // TODO: return Error
    }

    setLoading(true)
    setError(null)

    return woven
      .swap(
        account,
        new BigNumber(`${amount}e+${input.decimals}`).toFixed(),
        typed,
        {
          slippage,
          excludedExchanges,
          estimate: false
        }
      )
      .then(({ tx, price }) => ({ tx, order: { price } }))
      .catch(err => {
        setError(err)
        return Promise.reject(err) // TODO: err
      })
      .finally(() => setLoading(false))
  }, [
    woven,
    input,
    output,
    account,
    amount,
    typed,
    slippage,
    excludedExchanges,
    chainId
  ])

  return { swap, loading, error }
}

export default useSwap
