import { Trans } from '@lingui/macro'
import styles from './styles.module.scss'

const Error = () => (
  <div className={styles.error}>
    <div className={styles.title}>
      <Trans>Market Price Unavailable</Trans>
    </div>
    <div className={styles.chart}>
      <Trans>We’re still collecting data for this market...</Trans>
    </div>
  </div>
)

export default Error
