import { useState, useEffect, useContext, useMemo, useCallback } from 'react'
import axios, { CancelToken } from 'axios'
import { isAddress } from '@ethersproject/address'
import { ID, Currency, providers } from '@moverfinance/dex-sdk'
import { useDispatch } from 'react-redux'
import { ALL_IDS } from 'constants/chains'
import { NATIVE_CURRENCIES, RECOMMEND_CURRENCIES } from 'constants/currency'
import { add, useSelector } from 'store/currency'
import { useActiveWeb3 } from 'hooks/useWeb3'
import { useERC20Contract, useMulticallContract } from 'hooks/useContract'
import { Context, initData, InitData } from 'components/init'

export const useListener = () => {
  const [tokens, setTokens] = useState<InitData>(initData)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    setError(null)
    setLoading(true)
    Promise.all(
      ALL_IDS.map(chainId =>
        import(`assets/tokens/${chainId}.json`).then(
          ({ tokens }) =>
            tokens as Array<{
              chainId: number
              address: string
              decimals: number
              symbol: string
              name: string
            }>
        )
      )
    )
      .then(result => {
        const tokens = ALL_IDS.reduce<InitData>((res, chainId, i) => {
          const recommend = RECOMMEND_CURRENCIES[chainId]

          res[chainId] = result[i]
            .filter(token => isAddress(token.address))
            .sort(token =>
              recommend.includes(token.address.toLowerCase()) ? -1 : 1
            )
            .map(token => Currency.from(token))

          return res
        }, {} as InitData)

        setTokens(tokens)
      })
      .catch(err => {
        setTokens(initData)
        setError(err)
        console.error(err)
      })
      .finally(() => setLoading(false))
  }, [])

  return { tokens, loading, error }
}

export const useAllTokens = (chainId: ID) => {
  const tokens = useContext(Context)

  return useMemo(
    () => (chainId ? tokens[chainId as ID] : []),
    [tokens, chainId]
  )
}

export const useNativeCurrency = (chainId: ID) => {
  return useMemo(() => NATIVE_CURRENCIES[chainId as ID], [chainId])
}

export const useCustomCurrencis = (chainId: ID) => {
  const currencies = useSelector(state => state?.[chainId as ID])

  if (!currencies) return currencies
  return currencies.map(currency => Currency.from(currency))
}

export const useCurrencies = (chainId: ID) => {
  const tokens = useAllTokens(chainId)
  const customCurrencis = useCustomCurrencis(chainId)
  const nativeCurrency = useNativeCurrency(chainId)

  return useMemo(() => {
    let currencies = [...tokens]

    if (customCurrencis && customCurrencis.length) {
      currencies = [...customCurrencis, ...currencies]
    }
    if (nativeCurrency) {
      currencies = [nativeCurrency, ...currencies]
    }

    return currencies
  }, [nativeCurrency, customCurrencis, tokens])
}

export const useSearch = (chainId: ID, address: string) => {
  const { active } = useActiveWeb3()
  const [searching, setSearching] = useState(false)
  const dispatch = useDispatch()
  const currencies = useCurrencies(chainId as ID)
  const erc20 = useERC20Contract()
  const multicall = useMulticallContract()

  useEffect(() => {
    if (
      !isAddress(address) ||
      !active ||
      !chainId ||
      currencies.find(
        currency => currency.address.toLowerCase() === address.toLowerCase()
      )
    ) {
      return
    }
    const erc20Contract = erc20(address)

    setSearching(true)
    multicall(chainId)
      .connect(providers[chainId])
      .callStatic.aggregate([
        {
          target: address,
          callData: erc20Contract.interface.encodeFunctionData('name')
        },
        {
          target: address,
          callData: erc20Contract.interface.encodeFunctionData('symbol')
        },
        {
          target: address,
          callData: erc20Contract.interface.encodeFunctionData('decimals')
        }
      ])
      .then(({ returnData }) => {
        const [name] = erc20Contract.interface.decodeFunctionResult(
          'name',
          returnData[0]
        )
        const [symbol] = erc20Contract.interface.decodeFunctionResult(
          'symbol',
          returnData[1]
        )
        const [decimals] = erc20Contract.interface.decodeFunctionResult(
          'decimals',
          returnData[2]
        )

        dispatch(
          add(Currency.from({ chainId, address, name, symbol, decimals }))
        )
      })
      .catch(err => console.error(err))
      .finally(() => setSearching(false))
  }, [address, active, chainId, erc20, multicall, currencies, dispatch])

  return { searching }
}

export const useCurrency = (chainId: ID, address: string) => {
  const currencies = useCurrencies(chainId)
  const currency = useMemo(
    () => currencies.find(item => item.address === address),
    [currencies, address]
  )

  return currency
}

export const useCurrencyInfo = (currency: Currency | null) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [url, setURL] = useState('')
  const getInfo = useCallback(
    async (symbol: string, cancelToken: CancelToken) => {
      try {
        const { data } = await axios.get<{ url: string }>(
          `/api/currency/${symbol}`,
          {
            baseURL: process.env.REACT_APP_DEX_SERVER,
            cancelToken
          }
        )
        const { url = '' } = data

        return url
      } catch {
        return ''
      }
    },
    []
  )

  useEffect(() => {
    if (!currency) return
    const source = axios.CancelToken.source()

    setError(null)
    setURL('')
    setLoading(true)
    getInfo(currency.symbol, source.token)
      .then(url => setURL(url))
      .catch(err => setError(err))
      .finally(() => setLoading(false))

    return () => source.cancel('Get currency info operation canceled')
  }, [currency, getInfo])

  return { url, loading, error }
}

export default useCurrency
