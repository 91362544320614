import {
  useState,
  useMemo,
  useCallback,
  FC,
  useEffect,
  ReactElement
} from 'react'
import { Trans } from '@lingui/macro'
import cls from 'classnames'
import { ID, Currency } from '@moverfinance/dex-sdk'
import { CHAIN_INFO, ALL_IDS } from 'constants/chains'
import * as Icon from 'components/icon'
import Modal from 'components/modal'
import Loading from 'components/loading'
import { useActiveWeb3 } from 'hooks/useWeb3'
import { useCurrencies, useSearch } from 'hooks/useCurrency'
import { ReactComponent as Search } from 'assets/icons/search.svg'
import Balance from './balance'
import styles from './styles.module.scss'

interface Props {
  value: Currency | null
  title: ReactElement
  network?: boolean
  excludedCurrency?: Currency
  onChange: (address: Currency) => void
}

const CurrencySelector: FC<Props> = ({
  value,
  title,
  network = true,
  excludedCurrency,
  onChange
}) => {
  const { chainId } = useActiveWeb3()
  const [selectedChainId, setSelectedChainId] = useState(chainId || ID.MAINNET)
  const [visible, setVisible] = useState(false)
  const [search, setSearch] = useState('')
  const { searching } = useSearch(selectedChainId, search)
  const currencies = useCurrencies(selectedChainId)
  const searched = useMemo(
    () =>
      currencies.filter(
        currency =>
          currency.address
            .toLocaleUpperCase()
            .indexOf(search.toLocaleUpperCase()) >= 0 ||
          (currency.symbol &&
            currency.symbol.indexOf(search.toLocaleUpperCase()) >= 0) ||
          (currency.name && currency.name.indexOf(search) >= 0)
      ),
    [currencies, search]
  )
  const handleSelect = useCallback(
    (currency: Currency) => {
      onChange(currency)
      setVisible(false)
    },
    [onChange, setVisible]
  )
  const list = useMemo(() => {
    return searched
      .filter(
        (currency: Currency) =>
          !excludedCurrency || !currency.equals(excludedCurrency)
      )
      .slice(0, 20)
  }, [searched, excludedCurrency])

  useEffect(() => {
    if (visible) setSearch('')
  }, [visible])
  useEffect(() => {
    setSelectedChainId(chainId as ID)
  }, [chainId])

  return (
    <>
      <button className={styles.select} onClick={() => setVisible(true)}>
        {value ? (
          <>
            <Icon.Currency className={styles.icon} currency={value} />
            <div className={styles.name}>
              {value.symbol}
              {CHAIN_INFO[value.chainId] && (
                <div className={styles.network}>
                  on {CHAIN_INFO[value.chainId].label || <Trans>Unknown</Trans>}
                </div>
              )}
            </div>
          </>
        ) : (
          <div className={styles.default}>
            <Trans>Select Token</Trans>
          </div>
        )}
        <i className={styles.arrow} />
      </button>
      <Modal title={title} line={false} visible={visible} onClose={setVisible}>
        <div className={styles.currencies}>
          <div className={styles.search}>
            <Search className={styles.icon} />
            <input
              value={search}
              placeholder="Search or paste any token" // TODO: i18n
              onChange={e => setSearch(e.target.value)}
            />
          </div>
          {network && (
            <div className={styles.network}>
              {ALL_IDS.map(chainId => (
                <button
                  className={cls({
                    [styles.active]: chainId === selectedChainId
                  })}
                  key={chainId}
                  onClick={() => setSelectedChainId(chainId)}
                >
                  <Icon.Network id={chainId} color className={styles.icon} />
                  <span>
                    {CHAIN_INFO[chainId].label || <Trans>Unknown</Trans>}
                  </span>
                </button>
              ))}
            </div>
          )}
          <div className={styles.list}>
            {searching ? (
              <div className={styles.loading}>
                <Loading className={styles.icon} />
                <p>
                  <Trans>Collecting data...</Trans>
                </p>
              </div>
            ) : list.length ? (
              list.map(currency => (
                <div
                  className={styles.item}
                  key={currency.address}
                  onClick={() => handleSelect(currency)}
                >
                  <Icon.Currency className={styles.logo} currency={currency} />
                  <div className={styles.info}>
                    <h3>{currency.symbol}</h3>
                    <p>{currency.name}</p>
                  </div>
                  <div className={styles.balance}>
                    <Balance currency={currency} />
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.empty}>
                <Trans>No results found.</Trans>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default CurrencySelector
