import { useState, useMemo } from 'react'
import dayjs from 'dayjs'
import BigNumber from 'bignumber.js'
import cls from 'classnames'
import * as math from 'mathjs'
import { Select } from '@lingui/macro'
import { Currency } from '@moverfinance/dex-sdk'
import useTradeHistory from 'hooks/useTradeHistory'
import Tabs from 'components/tabs'
import * as Icon from 'components/icon'
import { ReactComponent as FallIcon } from 'assets/icons/fall.svg'
import Skeleton from './skeleton'
import Error from './error'
import Chart from './chart'
import styles from './styles.module.scss'

interface Props {
  input: Currency | null
  output: Currency | null
  onInputChange: (address: Currency) => void
  onOutputChange: (address: Currency) => void
}

const CONFIGS = {
  1: { days: 1, interval: 15 },
  7: { days: 7, interval: 30 },
  30: { days: 30, interval: 120 }
}

const formatNumber = (value: number, fixed = 0) => {
  const ceil = math.ceil as (x: number, v: number) => number

  if (value > 0) return '+' + ceil(math.abs(value), fixed)
  if (value < 0) return '-' + ceil(math.abs(value), fixed)
  return (0.0).toFixed(fixed)
}

const History = ({ input, output }: Props) => {
  const [config, setConfig] = useState({ days: 1, interval: 15 })
  const { history, loading, error } = useTradeHistory(
    input,
    output,
    config.days,
    config.interval
  )
  const [selected, setSelected] = useState<[number, number] | undefined>(
    undefined
  )
  const [date, price] = useMemo(
    () => selected || history[history.length - 1] || [0, 0],
    [selected, history]
  )
  const diff = useMemo(
    () => (history.length ? price - history[0][1] : 0),
    [history, price]
  )

  return (
    <div className={styles.history}>
      <div className={styles.container}>
        {input && output ? (
          <h3 className={styles.pair}>
            <a>{input.symbol || input.name || input.address}</a>
            <span className={styles.split} />
            <span>
              <a>{output.symbol || output.name || output.address}</a>
            </span>
          </h3>
        ) : (
          <h3 className={styles.simple}>
            {(input || output)?.name}
            {(input || output)?.symbol && (
              <span>{(input || output)?.symbol}</span>
            )}
            <Icon.Currency currency={input || output} className={styles.icon} />
          </h3>
        )}
        {loading ? (
          <Skeleton />
        ) : error || !history.length ? (
          <Error />
        ) : (
          <>
            {input && output ? (
              <div className={styles.amount}>
                {new BigNumber(price).toFixed(4)} {output.symbol}
              </div>
            ) : (
              <div className={styles.amount}>
                ${new BigNumber(price).toFormat(4)}
              </div>
            )}
            <div className={styles.diff}>
              <span
                className={cls(styles.value, {
                  [styles.up]: diff > 0,
                  [styles.down]: diff < 0
                })}
              >
                {diff !== 0 && (
                  <FallIcon
                    className={cls(styles.icon, {
                      [styles.up]: diff > 0
                    })}
                  />
                )}
                {formatNumber(diff, 4)}
                {diff !== 0 && (
                  <>({formatNumber((diff / history[0][1]) * 100, 2)}%)</>
                )}
              </span>
              <div className={styles['date-range']}>
                <Select
                  value={`D${config.days}`}
                  D1="Past 24 hours"
                  D7="Past 7 days"
                  D3="Past 30 days"
                  other={`Past ${config.days} days`}
                />
              </div>
            </div>
            <div className={styles.date}>
              {dayjs(date).format('H:mm A')}&nbsp; GMT
              {formatNumber(new Date().getTimezoneOffset() / -60)}
            </div>
            <Chart data={history} onSelect={setSelected} />
          </>
        )}
      </div>
      <div className={styles.footer}>
        <Tabs
          value={config.days}
          theme="light"
          size="small"
          onChange={days => setConfig(CONFIGS[days as 1 | 7 | 30])}
          items={[
            { value: 1, label: '1D' },
            { value: 7, label: '1W' },
            { value: 30, label: '1M' }
          ]}
        />
      </div>
    </div>
  )
}

export default History
