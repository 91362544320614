import { useMemo, FC } from 'react'
import { Trans } from '@lingui/macro'
import { NATIVE_CURRENCY_ADDRESS, Currency } from '@moverfinance/dex-sdk'
import { useCurrencyInfo } from 'hooks/useCurrency'
import * as Icon from 'components/icon'
import * as Gradient from 'components/gradient'
import { ReactComponent as Link } from 'assets/icons/link.svg'
import styles from './styles.module.scss'

interface Props {
  input: Currency | null
  output: Currency | null
}

const About: FC<Props> = ({ input, output }) => {
  const tokens = useMemo(
    () =>
      [input, output].filter(
        currency => currency && currency.address !== NATIVE_CURRENCY_ADDRESS
      ) as Currency[],
    [input, output]
  )
  const { url: inputURL } = useCurrencyInfo(input)
  const { url: outputURL } = useCurrencyInfo(output)
  const urls = useMemo(
    () => [inputURL, outputURL].filter(url => url),
    [inputURL, outputURL]
  )

  return (
    <>
      {!!tokens.length && (
        <div className={styles.addresses}>
          <h4 className={styles.title}>
            <Trans>Token Address</Trans>
          </h4>
          {tokens.map(currency => (
            <Gradient.Border key={currency.address} className={styles.currency}>
              <Icon.Currency
                className={styles.icon}
                currency={currency}
                network={false}
              />
              <span>{currency.address}</span>
            </Gradient.Border>
          ))}
        </div>
      )}
      {!!urls.length && (
        <div>
          <h4 className={styles.title}>
            <Trans>Detail</Trans>
          </h4>
          {urls.map((url, i) => (
            <div className={styles.link} key={url + i}>
              <span>{url}</span>
              <a href={url} target="_blank" rel="noreferrer">
                <Link />
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default About
