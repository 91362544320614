import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { ID } from '@moverfinance/dex-sdk'
import { NETWORK_URLS } from 'constants/chains'

// mainnet only
export default new WalletLinkConnector({
  url: NETWORK_URLS[ID.MAINNET],
  appName: 'Test',
  appLogoUrl: ''
})
