import { useState, useMemo } from 'react'
import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { CurrencyAmount, Price } from '@moverfinance/dex-sdk'
import { useSlippage } from 'hooks/useSetting'
import styles from './styles.module.scss'

interface Props {
  price: Price
}

const Rate = ({ price }: Props) => {
  const [inverted, setInverted] = useState(false)
  const [slippage] = useSlippage()
  const bestPrice = useMemo(
    () => (inverted ? price.invert() : price),
    [inverted, price]
  )
  const bestRate = useMemo(
    () => new BigNumber(bestPrice.toSignificant()),
    [bestPrice]
  )
  const miniRate = useMemo(
    () => bestRate.multipliedBy(1 - slippage / 100),
    [bestRate, slippage]
  )
  const bestReceived = useMemo(() => {
    const amount = CurrencyAmount.fromRawAmount(
      price.quoteCurrency,
      price.numerator
    )

    return new BigNumber(amount.toSignificant())
  }, [price])
  const miniReceived = useMemo(
    () => bestReceived.multipliedBy(1 - slippage / 100),
    [bestReceived, slippage]
  )

  return (
    <div>
      <h3 className={styles.label}>
        <Trans>Estimated Rate</Trans>
      </h3>
      <div className={styles.rate} onClick={() => setInverted(!inverted)}>
        <span>1 {bestPrice.baseCurrency.symbol}</span>
        <span> = </span>
        <span>
          {miniRate.dp(6).toFormat()} {bestPrice.quoteCurrency.symbol}
        </span>
        <span> ~ </span>
        <span>
          {bestRate.dp(6).toFormat()} {bestPrice.quoteCurrency.symbol}
        </span>
      </div>
      <table className={styles.price}>
        <tbody>
          <tr>
            <td>
              <h3 className={styles.label}>
                <Trans>Expected Received</Trans>
              </h3>
            </td>
            <td>
              {bestReceived.dp(18).toFormat()} {price.quoteCurrency.symbol}
            </td>
          </tr>
          <tr>
            <td>
              <h3 className={styles.label}>
                <Trans>Minimum Received</Trans>
              </h3>
            </td>
            <td>
              {miniReceived.dp(18).toFormat()} {price.quoteCurrency.symbol}
            </td>
          </tr>
          <tr>
            <td>
              <h3 className={styles.label}>
                <Trans>Max Price Slippage</Trans>
              </h3>
            </td>
            <td>{slippage}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Rate
