import { createAction } from '@reduxjs/toolkit'
import { ID } from '@moverfinance/dex-sdk'
import { TxApprove, TxSwap } from 'constants/tx'

export const add = createAction<{
  chainId: ID
  account: string
  tx: TxApprove | TxSwap
}>('setting/add')
export const clear = createAction<{
  chainId: ID
  account: string
}>('setting/clear')
