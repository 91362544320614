import { useEffect, useRef, useState } from 'react'
import { renderIcon } from '@download/blockies'
import styles from './styles.module.scss'

interface Props {
  address: string
}

const BlockieIdenticon = (props: Props) => {
  const { address } = props
  const [dataUrl, setDataUrl] = useState<string | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  useEffect(() => {
    if (canvasRef.current) {
      const canvas = canvasRef.current
      renderIcon({ seed: address.toLowerCase() }, canvas)
      const updatedDataUrl = canvas.toDataURL()

      if (updatedDataUrl !== dataUrl) {
        setDataUrl(updatedDataUrl)
      }
    }
  }, [address, dataUrl])

  return (
    <>
      <canvas ref={canvasRef} className={styles.canvas} />
      <img src={dataUrl as string} className={styles.avatar} alt="avatar" />
    </>
  )
}

export default BlockieIdenticon
