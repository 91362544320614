import { FC } from 'react'
import { Link } from 'react-router-dom'
import cls from 'classnames'
import styles from './styles.module.scss'

interface Props {
  className?: string
}

const Header: FC<Props> = ({ children, className }) => (
  <header className={cls(styles.header, className)}>
    <div>
      <Link className={styles.logo} to="/" />
      <div className={styles.operator}>{children}</div>
    </div>
  </header>
)

export default Header
