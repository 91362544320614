import { useCallback } from 'react'
import { Trans } from '@lingui/macro'
import { Currency, Typed } from '@moverfinance/dex-sdk'
import Button from 'components/button'
import * as Gradient from 'components/gradient'
import Modal, { UpdateProps as ModalUpdateProps } from 'components/modal'
import { useLingui } from '@lingui/react'
import useSwap from 'hooks/useSwap'
import useSendTx from 'hooks/useTx'
import Review from './review'

interface Props {
  input: Currency
  output: Currency
  amount: string
  typed: Typed
}

const Swap = ({ input, output, amount, typed }: Props) => {
  const { i18n } = useLingui()
  const sendTx = useSendTx()
  const {
    swap,
    loading: swaping,
    error: swapError
  } = useSwap(input, output, amount, typed)
  const handleSwap = useCallback(() => {
    return swap().then(detail => {
      const modal = Modal.open(
        {
          title: <Trans>Confirm Swap</Trans>,
          children: (
            <Review
              detail={detail}
              onSendTx={sendTx}
              onRefresh={swap}
              onClose={() => modal.close()}
              onUpdate={(props: ModalUpdateProps) => modal.update(props)}
            />
          )
        },
        i18n
      )
    })
  }, [swap, i18n, sendTx])

  if (swaping) {
    return (
      <Button size="large" block loading>
        <Gradient.Text>
          <Trans>Swap...</Trans>
        </Gradient.Text>
      </Button>
    )
  }

  return (
    <Button size="large" block onClick={handleSwap}>
      <Gradient.Text>
        {swapError ? (
          <Trans>Quote failed, please try again</Trans>
        ) : (
          <Trans>Swap</Trans>
        )}
      </Gradient.Text>
    </Button>
  )
}

export default Swap
