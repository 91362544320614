import { createContext, FC } from 'react'
import { Trans } from '@lingui/macro'
import { ID, Currency } from '@moverfinance/dex-sdk'
import { SupportedAllChainId } from 'constants/chains'
import { useListener } from 'hooks/useCurrency'
import Balance from 'components/balance'

export const initData: {
  [key in SupportedAllChainId]: Currency[]
} = {
  [ID.MAINNET]: [],
  [ID.ROPSTEN]: [],
  [ID.KOVAN]: [],
  [ID.BSC]: [],
  [ID.POLYGON]: [],
  [ID.FANTOM]: [],
  [ID.AVALANCHE]: []
}

export type InitData = typeof initData

export const Context = createContext<InitData>(initData)

const Init: FC = ({ children }) => {
  const { tokens, loading } = useListener()

  if (loading) {
    return (
      <div>
        <Trans>Loading assets...</Trans>
      </div>
    )
  }

  return (
    <Context.Provider value={tokens}>
      <Balance>{children}</Balance>
    </Context.Provider>
  )
}

export default Init
