import { createReducer } from '@reduxjs/toolkit'
import { Currency } from '@moverfinance/dex-sdk'
import { add, clear } from './actions'

export const initialState: {
  [chainId: number]: Currency[]
} = {}
export default createReducer(initialState, builder => {
  builder
    .addCase(add, (state, action) => {
      const currency = action.payload
      const currencies = state[currency.chainId] ?? []

      if (
        currencies.find(
          item => item.address.toLowerCase() === currency.address.toLowerCase()
        )
      ) {
        return
      }
      state[currency.chainId] = [currency, ...(state[currency.chainId] ?? [])]
    })
    .addCase(clear, (state, action) => {
      const { chainId } = action.payload

      state[chainId] = []
    })
})
