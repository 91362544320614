import { FC } from 'react'
import cls from 'classnames'
import Loading from 'components/loading'
import styles from './styles.module.scss'

interface Props {
  className?: string
  onClick?: () => void
  size?: 'large' | 'mini'
  line?: boolean
  block?: boolean
  disabled?: boolean
  loading?: boolean
}

const Button: FC<Props> = ({
  children,
  className,
  size,
  line,
  block,
  disabled,
  loading,
  onClick
}) => {
  return (
    <button
      className={cls(
        styles.button,
        {
          [styles[size as string]]: !!size,
          [styles.line]: line,
          [styles.block]: block,
          [styles.disabled]: disabled
        },
        className
      )}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {loading && <Loading className={styles.loading} />}
      {children}
    </button>
  )
}

export default Button
