import { cloneElement, FC } from 'react'
import { ID } from '@moverfinance/dex-sdk'
// import { ReactComponent as Ethereum } from 'assets/networks/ethereum.svg'
import { ReactComponent as Testnet } from 'assets/networks/testnet.svg'
import { ReactComponent as BSC } from 'assets/networks/bsc.svg'
import { ReactComponent as Polygon } from 'assets/networks/polygon.svg'
import { ReactComponent as Avalanche } from 'assets/networks/avalanche.svg'
import { ReactComponent as Fantom } from 'assets/networks/fantom.svg'
import { ReactComponent as EthereumColor } from 'assets/networks/ethereum-color.svg'
import { ReactComponent as TestnetColor } from 'assets/networks/testnet-color.svg'
import { ReactComponent as BSCColor } from 'assets/networks/bsc-color.svg'
import { ReactComponent as PolygonColor } from 'assets/networks/polygon-color.svg'
import { ReactComponent as AvalancheColor } from 'assets/networks/avalanche-color.svg'
import { ReactComponent as FantomColor } from 'assets/networks/fantom-color.svg'

interface Props {
  id: ID
  color?: boolean
  className?: string
}

const icons = {
  [ID.MAINNET]: <EthereumColor />,
  [ID.ROPSTEN]: <Testnet />,
  [ID.KOVAN]: <Testnet />,
  [ID.BSC]: <BSC />,
  [ID.POLYGON]: <Polygon />,
  [ID.AVALANCHE]: <Avalanche />,
  [ID.FANTOM]: <Fantom />
}

const colors = {
  [ID.MAINNET]: <EthereumColor />,
  [ID.ROPSTEN]: <TestnetColor />,
  [ID.KOVAN]: <TestnetColor />,
  [ID.BSC]: <BSCColor />,
  [ID.POLYGON]: <PolygonColor />,
  [ID.AVALANCHE]: <AvalancheColor />,
  [ID.FANTOM]: <FantomColor />
}

const Icon: FC<Props> = ({ id, color, className }) =>
  cloneElement((color ? colors[id] : icons[id]) || <Testnet />, {
    className
  })

export default Icon
