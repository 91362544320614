import { FunctionComponent } from 'react'
import { AbstractConnector } from '@web3-react/abstract-connector'
import injectedConnector from 'connectors/injected'
import portisConnector from 'connectors/portis'
import walletconnectConnector from 'connectors/walletconnect'
import coinbaseConnector from 'connectors/coinbase'
import { ReactComponent as MetaMask } from 'assets/icons/metamask.svg'
import { ReactComponent as WalletConnect } from 'assets/icons/walletconnect.svg'
import { ReactComponent as Coinbase } from 'assets/icons/coinbase.svg'
import { ReactComponent as Portis } from 'assets/icons/portis.svg'

export interface Wallet {
  key: string
  name: string
  mobile: boolean
  connector: AbstractConnector
  logo: FunctionComponent<{ className?: string }>
}

const injected = {
  key: 'injected',
  name: 'MetaMask',
  connector: injectedConnector,
  mobile: false,
  logo: MetaMask
}
const walletconnect = {
  key: 'walletconnect',
  name: 'WalletConnect',
  connector: walletconnectConnector,
  mobile: true,
  logo: WalletConnect
}
const coinbase = {
  key: 'coinbase',
  name: 'Coinbase Wallet',
  connector: coinbaseConnector,
  mobile: true,
  logo: Coinbase
}
const portis = {
  key: 'portis',
  name: 'Portis',
  connector: portisConnector,
  mobile: false,
  logo: Portis
}

export const wallets: Wallet[] = [injected, walletconnect, coinbase, portis]
