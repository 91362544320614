import { Exchange, Bridge } from '@moverfinance/dex-sdk'

export enum Slippage {
  HIGHER = 3,
  HIGH = 1,
  MEDIUM = 0.3,
  LOW = 0.1
}

export const SLIPPAGE_OPTIONS: Slippage[] = [
  Slippage.HIGHER,
  Slippage.HIGH,
  Slippage.MEDIUM,
  Slippage.LOW
]

export enum TxSpeed {
  FASTEST = 'fastest',
  FAST = 'fast',
  AVERAGE = 'average'
}

export const DEFAULT_GAS_PRICES: { [txSpeed in TxSpeed]: number } = {
  [TxSpeed.FASTEST]: 30,
  [TxSpeed.FAST]: 20,
  [TxSpeed.AVERAGE]: 10
}

export { Exchange, Bridge }
export const ALL_EXCHANGES: Exchange[] = [
  Exchange.Native,
  Exchange.ACryptos,
  Exchange.ApeSwap,
  Exchange.BakerySwap,
  Exchange.Balancer,
  Exchange.BalancerV2,
  Exchange.Bancor,
  Exchange.Belt,
  Exchange.CafeSwap,
  Exchange.CheeseSwap,
  Exchange.ComethSwap,
  Exchange.Component,
  Exchange.Cream,
  Exchange.CryptoCom,
  Exchange.Curve,
  Exchange.CurveV2,
  Exchange.Dfyn,
  Exchange.Dodo,
  Exchange.Ellipsis,
  Exchange.FirebirdOneSwap,
  Exchange.IronSwap,
  Exchange.JulSwap,
  Exchange.Kyber,
  Exchange.KyberDmm,
  Exchange.Lido,
  Exchange.Linkswap,
  Exchange.MakerPsm,
  Exchange.Mooniswap,
  Exchange.MStable,
  Exchange.Nerve,
  Exchange.PancakeSwap,
  Exchange.PancakeSwapV2,
  Exchange.Polydex,
  Exchange.QuickSwap,
  Exchange.Saddle,
  Exchange.Shell,
  Exchange.ShibaSwap,
  Exchange.Smoothy,
  Exchange.SushiSwap,
  Exchange.Swerve,
  Exchange.Uniswap,
  Exchange.UniswapV2,
  Exchange.UniswapV3,
  Exchange.WaultSwap,
  Exchange.XSigma,
  Exchange.Oneinch
]
