import {
  useState,
  useCallback,
  cloneElement,
  createElement,
  FC,
  ReactElement
} from 'react'
import cls from 'classnames'
import { Trans } from '@lingui/macro'
import { useTryConnect } from 'hooks/useWeb3'
import Modal from 'components/modal'
import { wallets, Wallet } from 'constants/wallet'
import * as Gradient from 'components/gradient'
import Loading from 'components/loading'
import Context from './context'
import styles from './styles.module.scss'

interface Props {
  children: ReactElement
}

const Connect: FC<Props> = ({ children }) => {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState<Wallet | null>(null)
  const [error, setError] = useState<Wallet | null>(null)
  const tryConnect = useTryConnect()
  const connect = useCallback(
    (wallet: Wallet) => {
      setLoading(wallet)
      setError(null)
      tryConnect(wallet.connector)
        .catch(() => setError(wallet))
        .finally(() => setLoading(null))
    },
    [tryConnect]
  )
  const open = useCallback(() => {
    setError(null)
    setLoading(null)
    setVisible(true)
  }, [setVisible])

  return (
    <>
      {cloneElement(children, { onClick: open })}
      <Modal
        visible={visible}
        onClose={setVisible}
        title={<Trans>Connect to Wallet</Trans>}
      >
        {error ? (
          <Context wallet={error}>
            <div className={styles.error}>
              <span className={styles.text}>
                <Trans>Error connecting</Trans>
              </span>
              <hr className={styles.line} />
              <button onClick={() => connect(error)}>
                <Trans>Try Again</Trans>
              </button>
            </div>
          </Context>
        ) : loading ? (
          <Context wallet={loading}>
            <Gradient.Border className={styles.connecting}>
              <Loading className={styles.icon} />
              <span>
                <Trans>Initializing...</Trans>
              </span>
            </Gradient.Border>
          </Context>
        ) : (
          <>
            {wallets.map(item => (
              <button
                key={item.key}
                className={cls(styles.item, {
                  [styles.mobile]: item.mobile
                })}
                onClick={() => connect(item)}
              >
                <span>{item.name}</span>
                {createElement(item.logo, { className: styles.logo })}
              </button>
            ))}
          </>
        )}
      </Modal>
    </>
  )
}

export default Connect
