import { FC } from 'react'
import { Trans } from '@lingui/macro'
import { useUserWeb3 } from 'hooks/useWeb3'
import Header from 'components/header'
import Footer from 'components/footer'
import Network from 'components/network'
import Wallet from 'components/wallet'
import Button from 'components/button'
import * as Gradient from 'components/gradient'
import Account from 'components/account'
import styles from './styles.module.scss'

const Layout: FC = ({ children }) => {
  const { active, account, error } = useUserWeb3()

  return (
    <div className={styles.layout}>
      <Header className={styles.header}>
        <Network />
        {active && account ? (
          <Account />
        ) : (
          <Wallet>
            <Button className={styles.wallet} onClick={open}>
              <Gradient.Text className={styles.text}>
                <Trans>Connect Wallet</Trans>
              </Gradient.Text>
            </Button>
          </Wallet>
        )}
      </Header>
      <div className={styles.main}>
        {error && <div className={styles.error}>{error.message}</div>}
        <div className={styles.body}>{children}</div>
      </div>
      <Footer />
    </div>
  )
}

export default Layout
