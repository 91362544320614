import { useCallback } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { Web3Provider } from '@ethersproject/providers'
import { useSelector } from 'store/tx'
import { useUserWeb3 } from 'hooks/useWeb3'
import useGasPrice from 'hooks/useGasPrice'
import { LibraryNotExistError } from 'errors/web3'

export interface Tx {
  from: string
  to: string
  data: string
  value: string
  gasLimit: string
}

export const useSendTx = () => {
  const { chainId, library } = useUserWeb3()
  const gasPrice = useGasPrice()
  const sendTx = useCallback(
    (tx: Tx) => {
      if (!library) return Promise.reject(new LibraryNotExistError())
      const { from, to, data, value, gasLimit } = tx

      return (library as Web3Provider)
        .getSigner()
        .sendTransaction({
          from,
          to,
          data,
          value: BigNumber.from(value).toHexString(),
          gasLimit: BigNumber.from(gasLimit).toHexString(),
          gasPrice: BigNumber.from(`${gasPrice}000000000`).toHexString() // gasPrice * 1e+9
        })
        .then(res => ({ ...res, chainId: chainId as number })) // fix chainId is 0 in Ropsten network
    },
    [library, gasPrice, chainId]
  )

  return sendTx
}

export const useRecentTxs = () => {
  const { account, chainId } = useUserWeb3()
  const txs = useSelector(state =>
    chainId && account ? state[chainId + account] : []
  )

  return txs
}

export default useSendTx
