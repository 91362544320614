import { Trans } from '@lingui/macro'
import cls from 'classnames'
import Loading from 'components/loading'
import styles from './styles.module.scss'

const Skeleton = () => (
  <div className={styles.skeleton}>
    <div className={cls(styles.title, styles.animation)} />
    <div className={styles.diff}>
      <div
        className={cls(styles.text, styles.animation)}
        style={{ width: 52 }}
      />
      <div
        className={cls(styles.text, styles.animation)}
        style={{ width: 115 }}
      />
    </div>
    <div className={cls(styles.text, styles.animation)} style={{ width: 96 }} />
    <div className={styles.chart}>
      <Loading className={styles.loading} />
      <Trans>Collecting data for this market...</Trans>
    </div>
  </div>
)

export default Skeleton
