import { cloneElement, FC } from 'react'
import { Bridge } from '@moverfinance/dex-sdk'
import { ReactComponent as Hop } from 'assets/bridges/hop.svg'
import { ReactComponent as AnySwap } from 'assets/bridges/anyswap.svg'

interface Props {
  id: Bridge
  className?: string
}

const icons = {
  [Bridge.Hop]: <Hop />,
  [Bridge.AnySwap]: <AnySwap />
}

const Icon: FC<Props> = ({ id, className }) =>
  cloneElement(icons[id], {
    className
  })

export default Icon
