import { useMemo, useCallback, useEffect } from 'react'
import { LinearGradient } from '@visx/gradient'
import { AreaClosed, LinePath, Line, Bar } from '@visx/shape'
import { scaleTime, scaleLinear } from '@visx/scale'
import { useTooltip } from '@visx/tooltip'
import { localPoint } from '@visx/event'
import { extent, bisector } from 'd3-array'

interface Props {
  data: Array<[number, number]>
  onSelect: (value: [number, number] | undefined) => void
}

const getDate = (d: [number, number]) => new Date(d[0])
const getValue = (d: [number, number]) => d[1]
const bisectDate = bisector((d: [number, number]) => new Date(d[0])).left

// chart size
const width = 600
const height = 257
const circle = 10

const Chart = (props: Props) => {
  const { data, onSelect } = props
  const { tooltipData, showTooltip, tooltipLeft, tooltipTop, hideTooltip } =
    useTooltip<[number, number]>()
  const color = useMemo(() => {
    if (!data.length || data.length === 1) return '#7A7A7A'
    const [, firstPrice] = data[0]
    const [, lastPrice] = data[data.length - 1]

    if (lastPrice - firstPrice > 0) return '#1DD200'
    if (lastPrice - firstPrice < 0) return '#FF295D'
    return '#000'
  }, [data])
  const dateScale = useMemo(
    () =>
      scaleTime({
        range: [circle / 2, width - circle / 2],
        domain: extent(data, getDate) as [Date, Date]
      }),
    [data]
  )
  const valueScale = useMemo(
    () =>
      scaleLinear({
        range: [height, 0],
        domain: extent(data, getValue) as [number, number],
        nice: true
      }),
    [data]
  )
  const handleTooltip = useCallback(
    (
      event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>
    ) => {
      const { x } = localPoint(event) || { x: 0 }
      const x0 = dateScale.invert(x)
      const index = bisectDate(data, x0, 1)
      const d0 = data[index - 1]
      const d1 = data[index]
      let d = d0
      if (d1 && getDate(d1)) {
        d =
          x0.valueOf() - getDate(d0).valueOf() >
          getDate(d1).valueOf() - x0.valueOf()
            ? d1
            : d0
      }
      showTooltip({
        tooltipData: d,
        tooltipLeft: dateScale(getDate(d)),
        tooltipTop: valueScale(getValue(d))
      })
    },
    [showTooltip, valueScale, dateScale, data]
  )

  useEffect(() => {
    if (onSelect) onSelect(tooltipData)
  }, [tooltipData, onSelect])

  return (
    <svg width={600} height={257}>
      <LinearGradient
        id="area-gradient"
        from={color}
        to={color}
        fromOpacity={0.1}
        toOpacity={0}
      />
      <AreaClosed<[number, number]>
        data={data}
        x={d => dateScale(getDate(d)) ?? 0}
        y={d => valueScale(getValue(d)) ?? 0}
        yScale={valueScale}
        fill="url(#area-gradient)"
      />
      <LinePath<[number, number]>
        data={data}
        x={d => dateScale(getDate(d)) ?? 0}
        y={d => valueScale(getValue(d)) ?? 0}
        strokeWidth={2}
        stroke={color}
      />
      <Bar
        x={0}
        y={0}
        width={600}
        height={257}
        fill="transparent"
        onTouchStart={handleTooltip}
        onTouchMove={handleTooltip}
        onMouseMove={handleTooltip}
        onMouseLeave={() => hideTooltip()}
      />
      {tooltipLeft && tooltipTop && (
        <g>
          <Line
            from={{ x: tooltipLeft, y: 0 }}
            to={{ x: tooltipLeft, y: tooltipTop - circle / 2 }}
            stroke="#000"
            strokeWidth={2}
            pointerEvents="none"
          />
          <circle
            cx={tooltipLeft}
            cy={tooltipTop + 1}
            r={circle / 2 - 2}
            fill={color}
            stroke={color}
            strokeWidth={2}
            pointerEvents="none"
          />
          <Line
            from={{ x: tooltipLeft, y: tooltipTop + circle / 2 + 2 }}
            to={{ x: tooltipLeft, y: 257 }}
            stroke="#000"
            strokeWidth={2}
            pointerEvents="none"
          />
        </g>
      )}
    </svg>
  )
}

export default Chart
