import { useState, useCallback, FC, ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { Currency, Typed, TYPED } from '@moverfinance/dex-sdk'
import { useUserWeb3 } from 'hooks/useWeb3'
import useQuoter from 'hooks/useQuoter'
import useBalance from 'hooks/useBalance'
import { useApprovalState } from 'hooks/useApprove'
import Wallet from 'components/wallet'
import Button from 'components/button'
import * as Gradient from 'components/gradient'
import CurrencyInput from './currency-input'
import Rate from './rate'
import Approve from './approve'
import Swap from './swap'
import Route from './route'
import styles from './styles.module.scss'

interface Props {
  input: Currency | null
  output: Currency | null
  setting: ReactElement
  onInputChange: (currency: Currency | null) => void
  onOutputChange: (currency: Currency | null) => void
}

const Panel: FC<Props> = ({
  children,
  setting,
  input,
  output,
  onInputChange,
  onOutputChange
}) => {
  const { active } = useUserWeb3()
  const [amount, setAmount] = useState('')
  const [typed, setTyped] = useState<Typed>(TYPED.INPUT)
  const {
    order,
    loading: quoting,
    error: quoteError,
    reset
  } = useQuoter(input, output, amount, typed)
  const { approvalState } = useApprovalState(
    input,
    order?.allowanceTarget,
    amount
  )
  const balance = useBalance(input)
  const onAmountChange = useCallback((amount: string, typed: Typed) => {
    setAmount(amount)
    setTyped(typed)
  }, [])
  const onCurrencyChange = useCallback(
    (currency: Currency, typed: Typed) => {
      if (typed === TYPED.INPUT) {
        onInputChange(currency)
      } else {
        onOutputChange(currency)
      }
      reset()
    },
    [onInputChange, onOutputChange, reset]
  )
  const invert = useCallback(() => {
    onInputChange(output)
    onOutputChange(input)
    if (order) setAmount(order.amount)
    reset()
  }, [input, output, order, reset, onInputChange, onOutputChange])
  let submit = (
    <Swap
      input={input as Currency}
      output={output as Currency}
      amount={amount}
      typed={typed}
    />
  )

  if (!order && quoting) {
    submit = (
      <Button size="large" block disabled loading>
        <Trans>Finding best price...</Trans>
      </Button>
    )
  } else if (quoteError) {
    submit = (
      <Button size="large" block disabled>
        <Gradient.Text>
          <Trans>Insufficient asset liquidity</Trans>
        </Gradient.Text>
      </Button>
    )
  } else if (!active) {
    submit = (
      <Wallet>
        <Button size="large" block>
          <Gradient.Text>
            <Trans>Connect Wallet</Trans>
          </Gradient.Text>
        </Button>
      </Wallet>
    )
  } else if (
    !input ||
    !output ||
    new BigNumber(amount || '0').isLessThanOrEqualTo('0')
  ) {
    submit = (
      <Button size="large" block disabled>
        <Gradient.Text>
          <Trans>Enter an amount</Trans>
        </Gradient.Text>
      </Button>
    )
  } else if (
    balance &&
    ((typed === TYPED.INPUT && new BigNumber(balance).isLessThan(amount)) ||
      (typed === TYPED.OUTPUT &&
        order &&
        new BigNumber(balance).isLessThan(order.amount)))
  ) {
    submit = (
      <Button size="large" block disabled>
        <Gradient.Text>
          <Trans>Insufficient balance</Trans>
        </Gradient.Text>
      </Button>
    )
  } else if (input && approvalState === false && order?.allowanceTarget) {
    submit = (
      <Approve
        currency={input}
        amount={typed === TYPED.INPUT ? amount : order?.amount}
        spender={order.allowanceTarget}
      />
    )
  }

  return (
    <div className={styles.page}>
      <div className={styles.left}>
        {input && output && order && (
          <Route route={order.route} input={input} output={output} />
        )}
        <div>{children}</div>
      </div>
      <div className={styles.right}>
        <div className={styles.panel}>
          <div className={styles.title}>
            <span className={styles.text}>
              <Trans>Swap</Trans>
            </span>
            {setting}
          </div>
          <div className={styles.body}>
            <CurrencyInput
              title={<Trans>You Pay</Trans>}
              currency={input}
              faitPrice
              network={false}
              excludedCurrency={output ?? undefined}
              amount={typed === TYPED.INPUT ? amount : order?.amount ?? ''}
              onCurrencyChange={currency =>
                onCurrencyChange(currency, TYPED.INPUT)
              }
              onAmountChange={amount => onAmountChange(amount, TYPED.INPUT)}
            >
              {balance && (
                <Button
                  size="mini"
                  className={styles.max}
                  onClick={() => onAmountChange(balance, TYPED.INPUT)}
                >
                  <Gradient.Text>
                    <Trans>Max</Trans>
                  </Gradient.Text>
                </Button>
              )}
            </CurrencyInput>
            <button className={styles.invert} onClick={invert} />
            <CurrencyInput
              title={<Trans>You Receive</Trans>}
              currency={output}
              disabled
              excludedCurrency={input ?? undefined}
              amount={typed !== TYPED.INPUT ? amount : order?.amount ?? ''}
              onCurrencyChange={currency =>
                onCurrencyChange(currency, TYPED.OUTPUT)
              }
              onAmountChange={amount => onAmountChange(amount, TYPED.OUTPUT)}
            />
            {!!order && (
              <div className={styles.order}>
                <Rate price={order.price} />
              </div>
            )}
            <div style={{ marginTop: order ? 20 : 40 }}>{submit}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Panel
