import { useMemo, ReactElement, FC } from 'react'
import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { Currency, CurrencyAmount } from '@moverfinance/dex-sdk'
import useFaitPrice from 'hooks/useFaitPrice'
import Tokens from './tokens'
import Balance from './balance'
import styles from './styles.module.scss'

interface Props {
  title: ReactElement
  currency: Currency | null
  amount: string
  disabled?: boolean
  excludedCurrency?: Currency
  faitPrice?: boolean
  network?: boolean
  onCurrencyChange: (token: Currency) => void
  onAmountChange: (token: string) => void
}

const CurrencyInput: FC<Props> = ({
  children,
  title,
  currency,
  amount,
  disabled,
  faitPrice,
  network,
  excludedCurrency,
  onCurrencyChange,
  onAmountChange
}) => {
  const currencyAmount = useMemo(
    () =>
      currency && amount && faitPrice
        ? CurrencyAmount.fromRawAmount(
            currency,
            new BigNumber(amount)
              .multipliedBy(`1e${currency.decimals}`)
              .toFixed(0)
          )
        : null,
    [currency, amount, faitPrice]
  )
  const { price } = useFaitPrice(currencyAmount)

  return (
    <div>
      <label className={styles.label}>
        <span>{title}</span>
        <span className={styles.balance}>
          <Trans>Balance:</Trans>&nbsp;
          <Balance currency={currency} symbol />
          {children}
        </span>
      </label>
      <div className={styles.currency}>
        <Tokens
          title={title}
          value={currency}
          network={network}
          excludedCurrency={excludedCurrency}
          onChange={onCurrencyChange}
        />
        <input
          type="text"
          pattern="(^[1-9]([0-9]*)|^[0-9])(?:[.]\d*)?"
          placeholder="0.0"
          value={amount}
          disabled={disabled}
          onChange={e => {
            if (e.target.validity.valid) onAmountChange(e.target.value)
          }}
        />
        {price && (
          <p className={styles.price}>
            ≈ ${new BigNumber(price).dp(6).toFormat()}
          </p>
        )}
      </div>
    </div>
  )
}

export default CurrencyInput
