import { useState, useCallback, useEffect } from 'react'
import BN from 'bignumber.js'
import { MaxUint256 } from '@ethersproject/constants'
import { BigNumber } from '@ethersproject/bignumber'
import { useDispatch } from 'react-redux'
import { NATIVE_CURRENCY_ADDRESS, Currency } from '@moverfinance/dex-sdk'
import { TYPE_APPROVE } from 'constants/tx'
import { add } from 'store/tx'
import { useUserWeb3 } from 'hooks/useWeb3'
import useBlockNumber from 'hooks/useBlockNumber'
import { useERC20Contract } from 'hooks/useContract'
import { calculateGasMargin } from 'utils/gas'

export const useApprovalState = (
  currency: Currency | null,
  spender?: string,
  amount?: string
) => {
  const { active, account } = useUserWeb3()
  const blockNumber = useBlockNumber()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [approvalState, setApprovalState] = useState<boolean | null>(null)
  const getContract = useERC20Contract()
  const getApprovalState = useCallback(async () => {
    if (
      !amount ||
      !spender ||
      !account ||
      !currency ||
      new BN(amount).lte('0') ||
      currency.address === NATIVE_CURRENCY_ADDRESS
    ) {
      return setApprovalState(null)
    }
    setLoading(true)
    try {
      const erc20 = getContract(currency.address)
      const formatedAmount = new BN(
        `${amount}e+${currency.decimals}`
      ).toString()
      const allowance = await erc20.allowance(account, spender)

      setApprovalState(allowance.gte(formatedAmount))
    } catch (err) {
      setError(err)
    }
    setLoading(false)
  }, [getContract, currency, account, spender, amount])

  useEffect(() => {
    if (!active) return setApprovalState(null)
    getApprovalState()
  }, [active, getApprovalState, blockNumber])

  return { approvalState, loading, error, reload: getApprovalState }
}

export const useApprove = (currency: Currency) => {
  const getContract = useERC20Contract(true)
  const dispatch = useDispatch()
  const { active, chainId, account } = useUserWeb3()
  const approve = useCallback(
    async (amount: string | BigNumber, spender: string) => {
      if (!active || !currency || !account) return Promise.reject() // TODO: return Error
      const erc20 = getContract(currency.address)
      const formatedAmount = new BN(
        `${amount}e+${currency.decimals}`
      ).toString()
      let useExact = false
      const estimatedGas = await erc20.estimateGas
        .approve(spender, MaxUint256)
        .catch(() => {
          useExact = true
          return erc20.estimateGas.approve(spender, formatedAmount)
        })

      return await erc20
        .approve(spender, useExact ? formatedAmount : MaxUint256, {
          gasLimit: calculateGasMargin(estimatedGas)
        })
        .then(tx => {
          dispatch(
            add({
              chainId: chainId as number,
              account,
              tx: {
                type: TYPE_APPROVE,
                currency,
                createdAt: Date().toString(),
                txid: tx.hash
              }
            })
          )
          return { ...tx, chainId: chainId as number }
        })
    },
    [active, chainId, account, currency, getContract, dispatch]
  )

  return approve
}

export default useApprove
