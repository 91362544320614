import { useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import styles from './styles.module.scss'

interface Props {
  visible: boolean
  zIndex?: number
  onClose?: (visible: boolean) => void
}

const Mask = (props: Props) => {
  const nodeRef = useRef<HTMLDivElement | null>(null)
  const { visible, zIndex, onClose } = props

  return (
    <CSSTransition
      in={visible}
      nodeRef={nodeRef}
      unmountOnExit
      timeout={300}
      classNames={{
        enter: styles['mask-enter'],
        enterActive: styles['mask-enter-active'],
        exit: styles['mask-exit'],
        exitActive: styles['mask-exit-active']
      }}
      style={{ zIndex }}
    >
      <div
        ref={nodeRef}
        className={styles.mask}
        onClick={() => onClose && onClose(false)}
      />
    </CSSTransition>
  )
}

export default Mask
