import { useState, useEffect, createContext, FC } from 'react'

export const DEFAULT_THEME = window.localStorage.getItem('theme') ?? 'light'
export const Context = createContext<{
  theme: string
  setTheme: (theme: string) => void
}>({
  theme: DEFAULT_THEME,
  setTheme: () => {} // eslint-disable-line @typescript-eslint/no-empty-function
})

const Provider: FC = ({ children }) => {
  const [theme, setTheme] = useState(DEFAULT_THEME)

  useEffect(() => {
    document.documentElement.setAttribute('theme', theme)
    window.localStorage.setItem('theme', theme)
  }, [theme])

  return (
    <Context.Provider value={{ theme, setTheme }}>{children}</Context.Provider>
  )
}

export default Provider
