import { useRef, useState, useCallback, ReactElement } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Trans } from '@lingui/macro'
import cls from 'classnames'
import Mask from 'components/mask'
import styles from './styles.module.scss'

interface Props {
  children: ReactElement | ReactElement[]
}

const Popup = (props: Props) => {
  const nodeRef = useRef<HTMLDivElement | null>(null)
  const { children } = props
  const [visible, setVisible] = useState(false)
  const handleSetVisible = useCallback(
    (visible: boolean) => setVisible(visible),
    []
  )

  return (
    <>
      <div
        className={cls(styles.switch, styles.pc)}
        onClick={() => handleSetVisible(!visible)}
      />
      <Mask
        zIndex={2}
        visible={visible}
        onClose={() => handleSetVisible(false)}
      />
      <CSSTransition
        in={visible}
        nodeRef={nodeRef}
        timeout={300}
        mountOnEnter
        classNames={{
          enter: styles['popup-enter'],
          enterActive: styles['popup-enter-active'],
          exit: styles['popup-exit'],
          exitActive: styles['popup-exit-active'],
          exitDone: styles['popup-exit-done']
        }}
      >
        <div ref={nodeRef} className={styles.popup}>
          <div className={styles.arrow} />
          <div className={styles.body}>
            <div className={styles.title}>
              <Trans>Transaction Settings</Trans>
            </div>
            {children}
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export default Popup
