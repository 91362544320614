import { ID } from '@moverfinance/dex-sdk'

// Unsupported testnet
export const COINGECKO_IDS: { [chainId in ID]: string | null } = {
  [ID.MAINNET]: 'ethereum',
  [ID.BSC]: 'binance-smart-chain',
  [ID.ROPSTEN]: null,
  [ID.KOVAN]: null,
  [ID.POLYGON]: 'polygon-pos',
  [ID.FANTOM]: 'fantom',
  [ID.AVALANCHE]: 'avalanche'
}

export const WRAPPED_ADDRESSED: { [chainId in ID]: string | null } = {
  [ID.MAINNET]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2', // WETH
  [ID.BSC]: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c', // WBNB
  [ID.ROPSTEN]: null,
  [ID.KOVAN]: null,
  [ID.POLYGON]: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
  [ID.FANTOM]: '0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83',
  [ID.AVALANCHE]: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7'
}
