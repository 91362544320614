import { useMemo, useCallback, useState, useEffect, useContext } from 'react'
import axios, { CancelToken } from 'axios'
import { ID } from '@moverfinance/dex-sdk'
import { useActiveWeb3 } from 'hooks/useWeb3'
import useBlockNumber from 'hooks/useBlockNumber'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import { useTxSpeed } from 'hooks/useSetting'
import { Context } from 'components/web3/gas'
import { SupportedAllChainId } from 'constants/chains'
import { DEFAULT_GAS_PRICES } from 'constants/setting'
import { ExternalServerError, InternalServerError } from 'errors/http'

export const GAS_URLS: { [key in SupportedAllChainId]: string } = {
  [ID.MAINNET]: 'https://gas.api.0x.org/source/median?output=eth_gas_station',
  [ID.BSC]: 'https://gas.bsc.api.0x.org/source/median?output=eth_gas_station',
  [ID.ROPSTEN]:
    'https://gas.ropsten.api.0x.org/source/median?output=eth_gas_station',
  [ID.KOVAN]:
    'https://gas.kovan.api.0x.org/source/median?output=eth_gas_station',
  [ID.POLYGON]:
    'https://gas.polygon.api.0x.org/source/median?output=eth_gas_station',
  [ID.FANTOM]:
    'https://gas.fantom.api.0x.org/source/median?output=eth_gas_station',
  [ID.AVALANCHE]:
    'https://gas.avalanche.api.0x.org/source/median?output=eth_gas_station'
}

export const useListener = () => {
  const { chainId } = useActiveWeb3()
  const blockNumber = useBlockNumber()
  const windowVisible = useIsWindowVisible()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | null>(null)
  const url = useMemo(() => GAS_URLS[chainId as ID], [chainId])
  const [gasPrices, setGasPrices] = useState(DEFAULT_GAS_PRICES)
  const getGasPrices = useCallback(
    async (cancelToken: CancelToken) => {
      setError(null)
      setLoading(true)
      try {
        const { data } = await axios.get<typeof DEFAULT_GAS_PRICES>(url, {
          cancelToken
        })

        try {
          setGasPrices({
            fastest: data.fastest / 10,
            fast: data.fast / 10,
            average: data.average / 10
          })
        } catch (err) {
          setGasPrices(DEFAULT_GAS_PRICES)
          setError(new InternalServerError(err))
        }
      } catch (err) {
        setGasPrices(DEFAULT_GAS_PRICES)
        setError(new ExternalServerError(url, err?.response?.data))
      }
      setLoading(false)
    },
    [url]
  )

  useEffect(() => {
    const source = axios.CancelToken.source()

    if (windowVisible && chainId) getGasPrices(source.token)
    return () => source.cancel('Get gas price operation canceled')
  }, [blockNumber, windowVisible, chainId, getGasPrices])

  return { gasPrices, loading, error }
}

export const useReqStatus = () => {
  const { loading, error } = useContext(Context)

  return { loading, error }
}

export const useGasPrices = () => {
  const { gasPrices } = useContext(Context)

  return gasPrices
}

export const useGasPrice = () => {
  const gasPrices = useGasPrices()
  const [txSpeed] = useTxSpeed()

  return useMemo(() => gasPrices[txSpeed], [gasPrices, txSpeed])
}

export default useGasPrice
