import { useState, useEffect, useCallback, useMemo } from 'react'
import axios, { CancelToken } from 'axios'
import BigNumber from 'bignumber.js'
import { Woven, Currency, Price, Typed, Route } from '@moverfinance/dex-sdk'
import useBlockNumber from 'hooks/useBlockNumber'
import { useActiveWeb3 } from 'hooks/useWeb3'
import { useSlippage, useExcludedExchanges } from 'hooks/useSetting'

export interface Order {
  amount: string
  price: Price
  allowanceTarget: string
  route: Route
}

export const useQuoter = (
  input: Currency | null,
  output: Currency | null,
  amount: string,
  typed: Typed
) => {
  const { chainId } = useActiveWeb3()
  const blockNumber = useBlockNumber()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [order, setOrder] = useState<Order | null>(null)
  const [slippage] = useSlippage()
  const [excludedExchanges] = useExcludedExchanges()
  const woven = useMemo(() => {
    if (!input || !output) return null
    return new Woven(input, output)
  }, [input, output])
  const quote = useCallback(
    async (amount: string, cancelToken: CancelToken) => {
      if (
        !chainId ||
        !woven ||
        !input ||
        !output ||
        !amount ||
        new BigNumber(amount).isLessThanOrEqualTo('0')
      ) {
        return setOrder(null)
      }

      setError(null)
      setLoading(true)
      try {
        const quotation = await woven.quote(
          new BigNumber(`${amount}e+${input.decimals}`).toFixed(),
          typed,
          {
            slippage,
            excludedExchanges,
            cancelToken
          }
        )

        setOrder({
          amount: new BigNumber(quotation.amount)
            .dividedBy(`1e${output.decimals}`)
            .toString(),
          price: quotation.price,
          allowanceTarget: quotation.target,
          route: quotation.route
        })
      } catch (err) {
        setError(err)
      }
      setLoading(false)
    },
    [woven, input, output, typed, slippage, excludedExchanges, chainId]
  )
  const reset = useCallback(() => {
    setOrder(null)
    setLoading(false)
    setError(null)
  }, [])

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    const timer = setTimeout(() => quote(amount, source.token), 800)
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
      source.cancel('cancel quote')
    }
  }, [quote, amount, blockNumber])

  return { order, loading, error, reset }
}

export default useQuoter
