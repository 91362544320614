import { useMemo } from 'react'
import { Trans } from '@lingui/macro'
import BigNumber from 'bignumber.js'
import { CurrencyAmount, Price } from '@moverfinance/dex-sdk'
import Loading from 'components/loading'
import styles from './styles.module.scss'

interface Props {
  price: Price
}

const Sending = ({ price }: Props) => {
  const { input, output } = useMemo(() => {
    const { baseCurrency, quoteCurrency, numerator, denominator } = price
    const input = CurrencyAmount.fromRawAmount(baseCurrency, denominator)
    const output = CurrencyAmount.fromRawAmount(quoteCurrency, numerator)

    return {
      input: new BigNumber(input.toSignificant()),
      output: new BigNumber(output.toSignificant())
    }
  }, [price])

  return (
    <div className={styles.sending}>
      <Loading className={styles.icon} />
      <h4 className={styles.title}>
        <Trans>Waiting For Confirmation</Trans>
      </h4>
      <p className={styles.description}>
        <Trans>
          Swapping
          <span>
            {input.dp(6).toFormat()} {price.baseCurrency.symbol}
          </span>
          for
          <span>
            {output.dp(6).toFormat()} {price.quoteCurrency.symbol}
          </span>
        </Trans>
      </p>
      <div className={styles.button}>
        <Trans>Confirm this transaction in your wallet</Trans>
      </div>
    </div>
  )
}

export default Sending
