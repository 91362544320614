import BigNumber from 'bignumber.js'
import { Currency } from '@moverfinance/dex-sdk'
import useBalance from 'hooks/useBalance'

interface Props {
  currency: Currency | null
  symbol?: boolean
}

const Balance = ({ currency, symbol }: Props) => {
  const balance = useBalance(currency)
  if (!balance || !currency) return <>-</>
  const formated = new BigNumber(balance).dp(6).toString()

  if (symbol)
    return (
      <>
        {formated} {currency.symbol}
      </>
    )
  return <>{formated}</>
}

export default Balance
