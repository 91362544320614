import { useState } from 'react'
import { Trans, Select } from '@lingui/macro'
import cls from 'classnames'
import { useGasPrices, useGasPrice } from 'hooks/useGasPrice'
import {
  useTxSpeed,
  useExcludedExchanges,
  useSlippage,
  useSlippageInput
} from 'hooks/useSetting'
import {
  TxSpeed,
  Exchange,
  ALL_EXCHANGES,
  SLIPPAGE_OPTIONS
} from 'constants/setting'
import Checkbox from 'components/checkbox'
import Tabs from 'components/tabs'
import Mobile from './mobile'
import PC from './pc'
import styles from './styles.module.scss'

const Body = () => {
  const gasPrices = useGasPrices()
  const gasPrice = useGasPrice()
  const [expanded, setExpanded] = useState(false)
  const [txSpeed, setTxSpeed] = useTxSpeed()
  const [excludedExchanges, setExcludedExchanges] = useExcludedExchanges()
  const [slippage, setSlippage] = useSlippage()
  const [inputValue, inputChange, inputBlur] = useSlippageInput()

  return (
    <div className={styles.setting}>
      <h5 className={styles.title}>
        <Trans>Slippage Tolerance</Trans>
        <span>{slippage}%</span>
      </h5>
      <div className={styles.slippage}>
        <Tabs
          value={slippage}
          onChange={value => setSlippage(value as number)}
          items={SLIPPAGE_OPTIONS.map(option => ({
            value: option,
            label: `${option}%`
          }))}
        />
        <div
          className={cls(styles.custom, {
            [styles.active]: !!inputValue
          })}
        >
          <input
            pattern="\d*(?:[.]\d*)?"
            placeholder="Custom" // TODO: i18n
            value={inputValue}
            onChange={e => {
              if (e.target.validity.valid) inputChange(e.target.value)
            }}
            onBlur={e => {
              inputBlur(e.target.value)
            }}
          />
        </div>
      </div>
      <h5 className={styles.title}>
        <Trans>Transaction Speed</Trans>
        <span>{gasPrice} Gwei</span>
      </h5>
      <div className={styles.speeds}>
        {Object.entries(gasPrices).map(([speed, value]) => (
          <label
            className={cls(styles.item, {
              [styles.active]: txSpeed === speed
            })}
            key={speed}
            htmlFor={speed}
          >
            <input
              id={speed}
              type="radio"
              name="txSpeed"
              checked={txSpeed === speed}
              value={speed}
              onChange={e => setTxSpeed(e.target.value as TxSpeed)}
            />
            <span>
              {value}{' '}
              <Select
                value={speed}
                fastest="Fast"
                fast="Instant"
                average="Standard"
                other="Unknow"
              />
            </span>
          </label>
        ))}
      </div>
      <hr className={styles.line} />
      <h5 className={styles.title}>
        <Trans>Exchanges</Trans>
        <span>
          {ALL_EXCHANGES.length - excludedExchanges.length}{' '}
          <Trans>Selected</Trans>
        </span>
        <i
          className={cls(styles.icon, {
            [styles.expanded]: expanded
          })}
          onClick={() => setExpanded(!expanded)}
        />
      </h5>
      <div
        className={cls(styles.exchanges, {
          [styles.expanded]: expanded
        })}
      >
        {ALL_EXCHANGES.map(exchange => (
          <div className={styles.item} key={exchange}>
            <Checkbox
              name="exchanges"
              value={exchange}
              checked={!excludedExchanges.includes(exchange)}
              onChange={value => setExcludedExchanges(value as Exchange)}
            >
              {exchange}
            </Checkbox>
          </div>
        ))}
      </div>
    </div>
  )
}

const Setting = () => {
  return (
    <>
      <PC>
        <Body />
      </PC>
      <Mobile>
        <Body />
      </Mobile>
    </>
  )
}

export default Setting
