export class ExternalServerError<T = undefined> extends Error {
  readonly data?: T
  constructor(url: string, data?: T) {
    super(`[Server Error]: External server error ${url}`)
    this.data = data
  }
}

export class InternalServerError extends Error {
  readonly data?: Error
  constructor(url: string, data?: Error) {
    super(`[Server Error]: External server error ${url}`)
    this.data = data
  }
}
