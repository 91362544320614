import { ReactElement } from 'react'
import { Trans } from '@lingui/macro'
import Button from 'components/button'
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg'
import styles from './styles.module.scss'

interface Props {
  children: ReactElement
  onClose: () => void
}

const Rejected = (props: Props) => {
  const { children, onClose } = props

  return (
    <div className={styles.reject}>
      <ErrorIcon className={styles.icon} />
      <p className={styles.description}>{children}</p>
      <Button block line size="large" onClick={onClose}>
        <Trans>Dismiss</Trans>
      </Button>
    </div>
  )
}

export default Rejected
